import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionItem } from '../../../models';

@Component({
  selector: 'lib-actionable-info-card',
  templateUrl: './actionable-info-card.component.html',
  styleUrl: './actionable-info-card.component.scss',
})
export class ActionableInfoCardComponent<T> {
  @Input() item?: ActionItem<T>;
  @Input() withExternalLink: boolean = true;
  @Input() displayDefaultHeader: boolean = true;
  @Input() cardSize?: 'default' | 'medium' = 'default';

  @Output() externalLinkClick: EventEmitter<ActionItem<T>> = new EventEmitter();
}
