<p-sidebar
  [baseZIndex]="900"
  [dismissible]="dismissible"
  [closeOnEscape]="dismissible"
  [styleClass]="'p-sidebar-' + size"
  position="right"
  [visible]="visible"
  (visibleChange)="visibleChange.emit($event)"
>
  <ng-template pTemplate="header">
    <h3 class="m-0">{{ title }}</h3>
  </ng-template>

  <ng-template pTemplate="content">
    <ng-content></ng-content>
  </ng-template>

  <ng-template pTemplate="footer">
    @if (secondaryBtn) {
      <button lib-button buttonType="cancel" (click)="secondaryClick.emit()">
        {{ secondaryBtn }}
      </button>
    }
    @if (primaryBtn) {
      <button
        lib-button
        buttonType="primary"
        class="ml-2"
        [disabled]="primaryBtnDisabled | boolean"
        [isLoading]="primaryBtnLoading | boolean"
        (click)="primaryClick.emit()"
      >
        {{ primaryBtn }}
      </button>
    }

    <ng-content select="footerContent"></ng-content>
  </ng-template>
</p-sidebar>
