import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../directives';
import { PipesModule } from '../pipes';
import { TableGridComponent } from './table-grid/table-grid.component';
import { TableGridExpandedRowComponent } from './table-grid/table-grid-expanded-row/table-grid-expanded-row.component';
import { TableHeaderRowComponent } from './table-grid/table-header-row/table-header-row.component';
import { TableBodyRowComponent } from './table-grid/table-body-row/table-body-row.component';
import { TablePaginatorComponent } from './table-paginator/table-paginator.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FeatureFlagModule } from '../feature-flag';
import { TableBodyRowActionCellComponent } from './table-grid/table-body-row/table-body-row-action-cell/table-body-row-action-cell.component';
import { TreeTableComponent } from './tree-table/tree-table.component';
import { DoesActionMenuHasItemsPipe } from './table-grid/table-body-row/table-body-row-action-cell/does-action-menu-has-items.pipe';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';
import { MaterialModule } from '../material/material.module';
import { IsNodeSelectedPipe } from './pipes/is-node-selected.pipe';
import { SkeletonModule } from 'primeng/skeleton';

@NgModule({
  declarations: [
    TableGridComponent,
    TableGridExpandedRowComponent,
    TableHeaderRowComponent,
    TableBodyRowComponent,
    TablePaginatorComponent,
    TableBodyRowActionCellComponent,
    TreeTableComponent,
    DoesActionMenuHasItemsPipe,
    IsNodeSelectedPipe,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    TranslateModule,
    DirectivesModule,
    FeatureFlagModule,
    PipesModule,
    ReactiveFormsModule,
    PrimeNgModule,
    MaterialModule,
    SkeletonModule,
  ],
  exports: [TableGridComponent, TreeTableComponent, TablePaginatorComponent],
})
export class TablesModule {}
