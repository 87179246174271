import { Injectable } from '@angular/core';
import {
  ApiResponse,
  ApplicationApiDefinition,
  CollaboratorDataRequest,
  CollaboratorDataRequestWithSource,
  DataRequestFieldComment,
  DataRequestFieldCommentReply,
  DataRequestFieldCommentType,
  DataRequestIndicatorLink,
  DataRequestNote,
  DataRequestPublicDocument,
  DataRequestSource,
  DataRequestSourceFilteringOptions,
  DataRequestSourceSubmission,
  DataRequestTransition,
  DataRequestValueGroupSet,
  DataRequestValueGroupSetStatus,
  FieldRejectionCommentsCount,
  LimitedUser,
  PaginationFilter,
  PublicDocUploadedFileMetaData,
} from '../../../models';
import { ApiService } from '../../common';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { BaseClientCollaboratorsService } from './base-client-collaborators.service';
import pickBy from 'lodash/pickBy';

@Injectable({
  providedIn: 'root',
})
export class ClientCollaboratorsService extends BaseClientCollaboratorsService {
  readonly apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  resourceV2: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    super();

    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.collaborators;
    this.resourceV2 = this.apiService.apiConfig.apis.collect.resources.collaboratorsV2;
  }

  public getCollaboratorDataRequests(
    options: DataRequestSourceFilteringOptions,
  ): Observable<ApiResponse<CollaboratorDataRequest[]>> {
    const params = this.filterOptionsToParams(options);
    return this.apiService.get(`${this.servicePath}${this.resource}/data_requests`, {
      params,
    });
  }

  public getCollaboratorDataRequestWithSource(
    dataRequestId: string,
    dataRequestSourceId: string,
  ): Observable<ApiResponse<CollaboratorDataRequestWithSource>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}`,
    );
  }

  public getCollaboratorDataRequestWithSourceIndicatorLinks(
    dataRequestId: string,
    dataRequestSourceId: string,
    withDeactivatedIndicators: boolean = false,
  ): Observable<ApiResponse<DataRequestIndicatorLink[]>> {
    const params = new HttpParams().append('with_deactivated_indicators', withDeactivatedIndicators);

    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/indicator_links`,
      { params },
    );
  }

  public getListOfUserFromDataRequestSourceForCollaborator(
    dataRequestId: string,
    dataRequestSourceId: string,
  ): Observable<ApiResponse<LimitedUser[]>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/users`,
    );
  }

  public getDataRequestSourceTicket(
    dataRequestId: string,
    dataRequestSourceId: string,
  ): Observable<ApiResponse<string>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/channel_ticket`,
    );
  }

  public postChangeStatusOfDataRequestVgset(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestVgsetId: string,
    status: DataRequestValueGroupSetStatus,
    note?: string,
  ): Observable<ApiResponse<DataRequestValueGroupSet>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/data_request_vgsets/${dataRequestVgsetId}`,
      {
        status,
        note: { note },
      },
    );
  }

  public postChangeStatusOfDataRequestSource(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestSourceSubmission: DataRequestSourceSubmission,
  ): Observable<ApiResponse> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}`,
      {
        status: dataRequestSourceSubmission.dataRequestSourceSubmitAction,
        note: { note: dataRequestSourceSubmission.note },
        users_responsibilities_email: dataRequestSourceSubmission.emailResponsibilities,
        sign_off_message: dataRequestSourceSubmission.signOffMessage,
      },
    );
  }

  public getValueDefinitionDocumentMetaData(
    dataRequestId: string,
    dataRequestSourceId: string,
    documentId: string,
    metricId: string,
    valueDefinitionId: string,
  ): Observable<ApiResponse<DataRequestPublicDocument>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/documents/${documentId}/metrics/${metricId}/value_definitions/${valueDefinitionId}/metadata`,
    );
  }

  public serveValueDefinitionDocument(
    dataRequestId: string,
    dataRequestSourceId: string,
    documentId: string,
    metricId: string,
    valueDefinitionId: string,
  ): Observable<Blob> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/documents/${documentId}/metrics/${metricId}/value_definitions/${valueDefinitionId}`,
      {
        headers: { 'Content-Type': 'application/octet-stream' },
        responseType: 'blob',
      },
    );
  }

  public getDataRequestNotes(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestNoteIds: string[],
  ): Observable<ApiResponse<DataRequestNote[]>> {
    let params = new HttpParams();
    dataRequestNoteIds.forEach((noteId: string) => {
      params = params.append('data_request_note_ids', noteId);
    });
    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/data_request_notes`,
      { params },
    );
  }

  public getDataRequestSourceTransitions(
    dataRequestId: string,
    dataRequestSourceId: string,
  ): Observable<ApiResponse<DataRequestTransition[]>> {
    const params = new HttpParams().append('load_notes', true);
    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestSourceId}/data_request_status_transitions`,
      { params },
    );
  }

  public getDataRequestValueGroupSetTransitions(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestVgsetId: string,
  ): Observable<ApiResponse<DataRequestTransition[]>> {
    const params = new HttpParams().append('load_notes', true);
    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestVgsetId}/value_group_set_transactions`,
      { params },
    );
  }

  private filterOptionsToParams(options: DataRequestSourceFilteringOptions): object {
    const params: any = {
      ['limit']: options.pagination?.limit,
      ['offset']: options.pagination?.offset,
      ['order_by']: options.ordering?.order_by,
      ['order_by_direction']: options.ordering?.order_by_direction,
    };

    if (options.search?.search_term) {
      params['search_term'] = options.search.search_term;
    }

    if (options.filters?.fiscal_year) {
      params['fiscal_year'] = options.filters.fiscal_year;
    }

    if (options.filters?.source_status) {
      params['source_status'] = options.filters.source_status;
    }

    return params;
  }

  public postDataRequestNoteReply(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestNoteId: string,
    replyText: string,
  ): Observable<ApiResponse<DataRequestNote>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/data_request_notes/${dataRequestNoteId}/reply`,
      { note: replyText },
    );
  }

  public addDataRequestFieldComment(
    dataRequestId: string,
    dataRequestSourceId: string,
    valueDefinitionId: string,
    valueGroupSetId: string,
    comment: string,
    commentType: DataRequestFieldCommentType,
    dataRequestValueGroupId?: string,
    fiscalYearPeriodId?: string,
  ): Observable<ApiResponse<DataRequestFieldComment>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/comments`,
      {
        value_definition_id: valueDefinitionId,
        data_request_value_group_set_id: valueGroupSetId,
        comment,
        comment_type: commentType,
        data_request_value_group_id: dataRequestValueGroupId,
        fiscal_year_period_id: fiscalYearPeriodId,
      },
    );
  }

  public listDataRequestFieldComments(
    dataRequestId: string,
    dataRequestSourceId: string,
    valueDefinitionId: string,
    valueGroupSetId: string,
    dataRequestValueGroupId?: string,
    fiscalYearPeriodId?: string,
  ): Observable<ApiResponse<DataRequestFieldComment[]>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/comments/list`,
      {
        value_definition_id: valueDefinitionId,
        data_request_value_group_set_id: valueGroupSetId,
        data_request_value_group_id: dataRequestValueGroupId,
        fiscal_year_period_id: fiscalYearPeriodId,
      },
    );
  }

  public resolveDataRequestFieldComment(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestSourceMetricId: string,
    commentId: string,
  ): Observable<ApiResponse<DataRequestFieldComment>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/data_request_source_metrics/${dataRequestSourceMetricId}/comments/${commentId}/resolve`,
    );
  }

  public restoreDataRequestFieldComment(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestSourceMetricId: string,
    commentId: string,
  ): Observable<ApiResponse<DataRequestFieldComment>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/data_request_source_metrics/${dataRequestSourceMetricId}/comments/${commentId}/restore`,
    );
  }

  public addDataRequestFieldCommentReply(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestSourceMetricId: string,
    commentId: string,
    comment: string,
  ): Observable<ApiResponse<DataRequestFieldCommentReply>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/data_request_source_metrics/${dataRequestSourceMetricId}/comments/${commentId}/replies`,
      {
        comment,
      },
    );
  }

  public countIndicatorRejectionComments(
    dataRequestId: string,
    dataRequestSourceId: string,
    indicatorId: string,
  ): Observable<ApiResponse<FieldRejectionCommentsCount>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/indicator/${indicatorId}/count_rejection_comments`,
    );
  }

  public listDataRequestFieldCommentReplies(
    dataRequestId: string,
    dataRequestSourceId: string,
    commentId: string,
    paginationFilter: PaginationFilter,
  ): Observable<ApiResponse<DataRequestFieldCommentReply[]>> {
    const params: Record<string, unknown> = {
      ['page']: paginationFilter.page,
      ['page_size']: paginationFilter.page_size,
      ['initial_offset']: paginationFilter.initial_offset,
    };

    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/comments/${commentId}/replies`,
      { params: pickBy(params, (param: unknown) => param != null) },
    );
  }

  public listDocumentsUploadedForDataRequest(
    dataRequestId: string,
    dataRequestSourceId: string,
    indicatorId: string,
    valueGroupSetId: string,
    documentIds: string[],
    showHistoricalData: boolean = false,
    showRecommendationData: boolean = false,
  ): Observable<ApiResponse<PublicDocUploadedFileMetaData[]>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/indicators/${indicatorId}/value_group_sets/${valueGroupSetId}/documents/metadata`,
      {
        show_historical_data: showHistoricalData,
        show_recommendation_data: showRecommendationData,
        document_ids: documentIds,
      },
    );
  }

  public postAutomaticImportDataRequestSourceV2(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestVgsetIds: string[],
    signOffMessage?: string,
  ): Observable<ApiResponse<DataRequestSource>> {
    return this.apiService.post(
      `${this.servicePath}${this.resourceV2}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/automatic_import`,
      {
        data_request_vgset_ids: dataRequestVgsetIds,
        sign_off_message: signOffMessage,
      },
    );
  }

  public postChangeStatusOfDataRequestSourceV2(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestSourceSubmission: DataRequestSourceSubmission,
  ): Observable<ApiResponse> {
    return this.apiService.post(
      `${this.servicePath}${this.resourceV2}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}`,
      {
        note: { note: dataRequestSourceSubmission.note },
        sign_off_message: dataRequestSourceSubmission.signOffMessage,
      },
    );
  }
}
