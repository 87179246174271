@if (isRowReorderable) {
  <th class="reorder-column-size"></th>
}

@if (enableCheckboxSelection) {
  <th class="action-column-size">
    @if (selectAllOption) {
      <p-tableHeaderCheckbox />
    }
  </th>
}

@if (isExpandable) {
  <th class="action-column-size"></th>
}

@for (column of columns; track column; let last = $last) {
  @if (!column.isSortable) {
    <th
      pResizableColumn
      [ngStyle]="{
        width: column.width ?? 'unset',
        'max-width': column.width ?? 'unset',
        'min-width': column.minWidth ?? 'unset',
      }"
      class="wrapping-value"
      [ngClass]="{ 'separator-cell': column.isSeparator }"
    >
      <ng-container
        *ngTemplateOutlet="column.headerTemplate || defaultHeader; context: { $implicit: column.name }"
      ></ng-container>
      <ng-template #defaultHeader>{{ column.noHeader ? "" : column.name }}</ng-template>

      @if (column.enableFilter) {
        <p-columnFilter
          [field]="$any(column.dataKey)"
          matchMode="contains"
          display="menu"
          [showMatchModes]="false"
          [showOperator]="false"
          [showAddButton]="false"
          [hideOnClear]="true"
        >
        </p-columnFilter>
      }

      @if (
        horizontalPaginationConfig && horizontalPaginationConfig.pageSize < horizontalPaginationConfig.total && last
      ) {
        <div
          class="horizontal-pagination"
          [ngClass]="{ 'horizontal-pagination-loading': horizontalPaginationConfig.isLoading }"
        >
          @if (!horizontalPaginationConfig.isLoading) {
            <p-button
              icon="pi pi-arrow-left"
              data-testid="prev-horizontal-page"
              [rounded]="true"
              [text]="true"
              severity="secondary"
              [disabled]="horizontalPaginationConfig.currentIndex <= 0"
              (click)="horizontalPageChange(-1)"
            ></p-button>
            <p-button
              icon="pi pi-arrow-right"
              data-testid="next-horizontal-page"
              [rounded]="true"
              [text]="true"
              [disabled]="
                horizontalPaginationConfig.currentIndex + horizontalPaginationConfig.pageSize >=
                horizontalPaginationConfig.total
              "
              severity="secondary"
              (click)="horizontalPageChange(1)"
            >
            </p-button>
          } @else {
            <lib-spinner data-testid="loading-horizontal-page" [diameter]="15"></lib-spinner>
          }
        </div>
      }
    </th>
  } @else {
    <th
      pResizableColumn
      [pSortableColumn]="$any(column.dataKey)"
      [ngStyle]="{ width: column.width ?? 'unset', 'max-width': column.width ?? 'unset' }"
    >
      {{ column.name }}
      <p-sortIcon [field]="$any(column.dataKey)"></p-sortIcon>
    </th>
  }
}

@if (actionMenuItems?.length) {
  <th class="action-column-size"></th>
}
