import { Component, Input } from '@angular/core';
import { ProgressBarV2ClassColours } from '../../../models';

@Component({
  selector: 'lib-progress-bar-v2',
  templateUrl: './progress-bar-v2.component.html',
  styleUrls: ['./progress-bar-v2.component.scss'],
})
export class ProgressBarV2Component {
  @Input() showValue: boolean = true;
  @Input() progress: number = 0;
  @Input() sourceClass: string = ProgressBarV2ClassColours.GREY_PROGRESS;
}
