import { Component, OnInit, Input } from '@angular/core';

import { SearchService } from '../../search';
import { Collection, Presentation, PresentationV2, SearchOptions } from '../../models';

@Component({
  selector: 'lib-template-section',
  templateUrl: './template-section.component.html',
  styleUrls: ['./template-section.component.scss'],
})
export class TemplateSectionComponent implements OnInit {
  @Input() title: string = '';
  @Input() link: string = '';
  @Input() linkPosition: 'before' | 'after' = 'before';
  @Input() searchOptions?: SearchOptions;
  @Input() cache?: string;

  loaded: boolean = false;
  ePresentation = Presentation;
  ePresentationV2 = PresentationV2;

  itemCollection?: Collection<any>;
  constructor(private searchService: SearchService) {}

  ngOnInit(): void {
    this.search();
  }

  search(): void {
    this.loaded = false;
    this.searchService.search(this.searchOptions!, this.cache).subscribe((result) => {
      this.itemCollection = result;
      this.loaded = true;
    });
  }
}
