import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, map, Observable, of, switchMap, take } from 'rxjs';
import { MetricApiService } from '../../../../services/types';

export class MetricStructurePropertiesValidators {
  static isNumber(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      return !isNaN(Number(control.value)) &&
        parseInt(String(control.value)) >= 0 &&
        !String(control.value).includes('.')
        ? null
        : {
            notNumeric: true,
          };
    }
    return null;
  }

  static isNotOnlySpaces(control: AbstractControl): ValidationErrors | null {
    return control.value?.length && control.value.trim() === ''
      ? {
          required: true,
        }
      : null;
  }

  static isMaxBiggerThanMin(formGroup: AbstractControl): ValidationErrors | null {
    const minimumFormControl: AbstractControl | null = formGroup.get('minimum');
    const maximumFormControl: AbstractControl | null = formGroup.get('maximum');
    if (
      minimumFormControl?.value?.length &&
      maximumFormControl?.value?.length &&
      +minimumFormControl.value >= +maximumFormControl.value
    ) {
      minimumFormControl.setErrors({ minMax: true });
      maximumFormControl.setErrors({ maxMin: true });
      return { minMax: true };
    }
    minimumFormControl?.setErrors(null);
    maximumFormControl?.setErrors(null);
    return null;
  }

  static is0OrGreaterThan1(control: AbstractControl): ValidationErrors | null {
    if (control?.value && +control?.value === 1) {
      return { isNot0OrGreaterThan1: true };
    }
    return null;
  }
  static dateRangeValidator(formGroup: AbstractControl): ValidationErrors | null {
    const minDateControl = formGroup.get('minDateRange');
    const maxDateControl = formGroup.get('maxDateRange');

    if (!minDateControl || !maxDateControl) {
      return null;
    }

    const minDate: string = minDateControl.value;
    const maxDate: string = maxDateControl.value;

    if (!minDate || !maxDate) {
      minDateControl.setErrors(null);
      maxDateControl.setErrors(null);
      return null;
    }

    if (new Date(minDate) >= new Date(maxDate)) {
      maxDateControl.setErrors({ maxLessThanMin: true });
      minDateControl.setErrors({ minGreaterThanMax: true });
      return { maxLessThanMin: true };
    } else {
      maxDateControl.setErrors(null);
      minDateControl.setErrors(null);
      return null;
    }
  }

  static validateFormula(metricsService: MetricApiService): AsyncValidatorFn {
    return (control: AbstractControl<string>): Observable<ValidationErrors | null> => {
      if (control.pristine) {
        return of(null);
      } else {
        return control.valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          take(1),
          switchMap(() => metricsService.validateFormulaVariables(control.value)),
          map((): ValidationErrors | null => null),
          catchError(() => of({ tooManyVariables: true })),
        );
      }
    };
  }
}
