import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { iif, Observable, of, Subject, Subscription, takeUntil } from 'rxjs';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import {
  ConfirmationDialogConfig,
  DataRequestSourceStatus,
  DataRequestUserResponsibility,
  DataRequestValueGroupSetStatus,
  DialogResult,
  Indicator,
  ItemType,
  Period,
  PlatformValueGroupSetStatus,
  ReportIntegrationType,
  SOURCE_CONFIGURATION,
  Status,
  ValueGroup,
  YearToDateCalculation,
} from '../../../models';
import { TranslateService } from '../../../services/common/translate/translate.service';
import { ResetValueOptions, ValueFormControl } from '../../models/valueFormControl';
import { UpsertValue } from '../../models/upsertValue';
import { MetricEditorNumericFieldComponent } from '../metric-editor-numeric-field/metric-editor-numeric-field.component';
import { DEFAULT_DOCUMENT_CONTEXT, DocumentContext } from '../../models/documentContext';
import { ConfirmationDialogComponent, DialogsService } from '../../../dialogs';
import { FormUtils } from '../../../classes';
import { isNullishValue } from '../../utils/valueUtils';
import { ValueGroupFormGroup } from '../../models/valueGroupFormGroup';
import { waitForNextUpdate } from '../../utils/operators';

interface FrequencyValueAndControl {
  newValue: unknown;
  control: ValueFormControl;
}

@Component({
  selector: 'lib-metric-editor-frequency-handler',
  templateUrl: './metric-editor-frequency-handler.component.html',
  styleUrls: ['./metric-editor-frequency-handler.component.scss'],
})
export class MetricEditorFrequencyHandlerComponent implements OnInit, OnDestroy {
  @Input({ required: true }) valueFormControl!: ValueFormControl;
  @Input({ required: true }) valueGroupFormGroup!: ValueGroupFormGroup;
  @Input({ required: true }) sourceConfiguration!: SOURCE_CONFIGURATION;
  @Input({ required: true }) valueGroup!: ValueGroup;
  @Input() indicator?: Indicator;
  @Input() documentContext: DocumentContext = DEFAULT_DOCUMENT_CONTEXT;
  @Input() indicatorId: string = '';
  @Input() vgsetId: string = '';
  @Input() displayFieldActions: boolean = false;
  @Input() collaboratorResponsibility?: DataRequestUserResponsibility;
  @Input() valueGroupSetStatus!: PlatformValueGroupSetStatus | DataRequestValueGroupSetStatus;
  @Input() dataRequestSourceStatus!: DataRequestSourceStatus;
  @Input() integrationType: ReportIntegrationType | null = null;
  @Input() disableFrequencyFields: boolean = false;
  @Input() fiscalYearPeriod?: Period;

  @Output() update: EventEmitter<UpsertValue> = new EventEmitter<UpsertValue>();
  @Output() resetValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() metricLinkEdit: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('valueFieldRef') valueFieldRef?: MetricEditorNumericFieldComponent;

  frequenciesControls: ValueFormControl[] = [];

  readonly eItemType = ItemType;

  private destroy$ = new Subject<void>();
  private frequencyValueChanges$ = new Subject<FrequencyValueAndControl>();
  private frequencyValueChangesSubscription?: Subscription;

  constructor(
    private translateService: TranslateService,
    private readonly dialogService: DialogsService,
  ) {}

  ngOnInit(): void {
    this.frequenciesControls = this.valueGroupFormGroup.getFrequenciesControlForValueFormControl(this.valueFormControl);
    this.setupHandler();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupHandler() {
    this.setupFormControlChangesSubscription();
    this.setupFrequencyValueChangesSubscription();
    this.setupValueResetsSubscription();
  }

  private setupFrequencyValueChangesSubscription(): void {
    this.frequencyValueChangesSubscription?.unsubscribe();
    this.frequencyValueChangesSubscription = this.frequencyValueChanges$
      .pipe(
        waitForNextUpdate(this.valueFormControl),
        mergeMap((value: FrequencyValueAndControl) =>
          iif(
            () => !isNullishValue(value.newValue),
            this.handleUpdateFrequencyValue(value.newValue, value.control),
            this.handleImplicitResetValue(value.newValue, value.control),
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private setupFormControlChangesSubscription(): void {
    this.frequenciesControls.forEach((frequencyControl) => {
      frequencyControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
        next: (value) => this.frequencyValueChanges$.next({ newValue: value, control: frequencyControl }),
      });
    });
  }

  private handleUpdateFrequencyValue(
    newValue: unknown,
    frequencyValueFormControl: ValueFormControl,
  ): Observable<boolean> {
    return of(newValue).pipe(
      filter(() => frequencyValueFormControl.valid),
      switchMap((newValue: unknown) => {
        const isValid = this.validate_ytd_value_for_period_value_change(frequencyValueFormControl);
        if (!isValid) {
          return of(false);
        }

        this.emitUpdate(newValue, frequencyValueFormControl);
        return of(true);
      }),
    );
  }

  private calculateYTDValue(updatedControl: ValueFormControl, ytdRule: YearToDateCalculation): number | null {
    switch (ytdRule) {
      case YearToDateCalculation.SUM:
        return this.calculateSum(updatedControl);
      case YearToDateCalculation.AVERAGE:
        return this.calculateAverage(updatedControl);
      case YearToDateCalculation.LATEST:
        return this.calculateLatest(updatedControl);
      default:
        return null;
    }
  }

  private validateYTDValue(
    updatedControl: ValueFormControl,
    ytdControl: ValueFormControl,
  ): { isValid: boolean; errorDetails?: any } {
    const ytdValidators = ytdControl.valueRef.validators;
    const ytdRule = ytdControl.valueRef.value_definition_frequency?.year_to_date_calculation;

    if (!ytdValidators || ytdValidators.length === 0 || !ytdRule) {
      return { isValid: true };
    }

    const newYTDValue = this.calculateYTDValue(updatedControl, ytdRule);

    if (newYTDValue === null) {
      return { isValid: false };
    }

    for (const validator of ytdValidators) {
      if (validator.validator_type === 'min_val' && validator.instructions) {
        const minVal = parseFloat(String(validator.instructions));
        if (newYTDValue < minVal) {
          return {
            isValid: false,
            errorDetails: {
              type: 'ytdBelowMin',
              actual: newYTDValue,
              min: minVal,
              period: updatedControl.valueRef.fiscal_year_period?.period,
              periodValue: String(updatedControl.value),
            },
          };
        }
      } else if (validator.validator_type === 'max_val' && validator.instructions) {
        const maxVal = parseFloat(String(validator.instructions));
        if (newYTDValue > maxVal) {
          return {
            isValid: false,
            errorDetails: {
              type: 'ytdExceeded',
              actual: newYTDValue,
              max: maxVal,
              period: String(updatedControl.valueRef.fiscal_year_period?.period),
              periodValue: String(updatedControl.value),
            },
          };
        }
      }
    }

    return { isValid: true };
  }

  private validate_ytd_value_for_period_value_change(updatedControl: ValueFormControl): boolean {
    const ytdControl = this.valueFormControl;
    const validationResult = this.validateYTDValue(updatedControl, ytdControl);

    if (!validationResult.isValid) {
      if (validationResult.errorDetails) {
        this.valueFormControl.setErrors({
          [validationResult.errorDetails.type]: validationResult.errorDetails,
        });
      }
      return false;
    }

    this.valueFormControl.setErrors(null);
    return true;
  }

  private calculateSum(updatedControl: ValueFormControl): number {
    return this.frequenciesControls.reduce((sum, control) => {
      if (control !== updatedControl && control.value !== null && control.value !== undefined) {
        return sum + parseFloat(String(control.value || '0'));
      } else if (control === updatedControl) {
        return sum + parseFloat(String(updatedControl.value || '0'));
      }
      return sum;
    }, 0);
  }

  private calculateAverage(updatedControl: ValueFormControl): number {
    let totalCount = 0;
    const totalSum = this.frequenciesControls.reduce((sum, control) => {
      if (control !== updatedControl && control.value !== null && control.value !== undefined) {
        totalCount++;
        return sum + parseFloat(String(control.value || '0'));
      } else if (control === updatedControl) {
        totalCount++;
        return sum + parseFloat(String(updatedControl.value) || '0');
      }
      return sum;
    }, 0);

    return totalCount > 0 ? totalSum / totalCount : 0;
  }

  private calculateLatest(updatedControl: ValueFormControl): number {
    const validControls = this.frequenciesControls.filter(
      (control) => control.value !== null && control.value !== undefined,
    );

    const sortedControls = [...validControls].sort((a, b) => {
      const aPeriod = parseInt(a.valueRef.fiscal_year_period?.period?.replace(/\D/g, '') || '0');
      const bPeriod = parseInt(b.valueRef.fiscal_year_period?.period?.replace(/\D/g, '') || '0');
      return aPeriod - bPeriod;
    });

    const latestControl = sortedControls[sortedControls.length - 1];
    if (latestControl === updatedControl) {
      return parseFloat(String(updatedControl.value));
    }
    return parseFloat(String(latestControl?.value));
  }

  private handleImplicitResetValue(value: unknown, frequencyValueFormControl: ValueFormControl): Observable<unknown> {
    return of(value).pipe(tap(() => frequencyValueFormControl.resetValue({ shouldPrompt: false })));
  }

  private setupValueResetsSubscription(): void {
    this.frequenciesControls.forEach((frequencyControl) => {
      frequencyControl.valueResets
        .pipe(
          switchMap((options: ResetValueOptions | undefined) =>
            options?.shouldPrompt ? this.promptResetValueConfirmationDialog() : of(true),
          ),
          filter(Boolean),
          tap(() => frequencyControl.reset()),
          tap(() => {
            if (!FormUtils.isNullOrEmpty(frequencyControl.valueRef.id)) {
              this.resetValue.emit(frequencyControl.valueRef.id);
            }
          }),
          tap(() => this.setupFrequencyValueChangesSubscription()),
          takeUntil(this.destroy$),
        )
        .subscribe();
    });
  }

  private promptResetValueConfirmationDialog(): Observable<boolean> {
    return this.dialogService
      .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
        data: {
          warningMsg: this.translateService.instant(
            'Are you sure you want to reset the value of this field? This cannot be undone',
          ),
          primaryBtn: this.translateService.instant('Reset'),
        },
      })
      .afterClosed()
      .pipe(map((result: DialogResult | undefined) => result?.status === Status.CONFIRMED));
  }

  private emitUpdate(newValue: unknown, valueFormControl: ValueFormControl): void {
    this.handlePendingCreation(valueFormControl);
    this.update.emit(valueFormControl.toUpsertValue(newValue, this.valueFormControl.valueRef));
  }

  private handlePendingCreation(valueFormControl: ValueFormControl): void {
    if (valueFormControl.valueRef.id == null || valueFormControl.valueRef.id === '') {
      this.valueFormControl.waitForNextUpdate();
    }
  }
}
