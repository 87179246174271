import { HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { delay, map } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import {
  ApiResponse,
  ApplicationApiDefinition,
  CodeCheck,
  ConditionalTrigger,
  ConditionalTriggerUpsertPayload,
  FieldInformationRequest,
  IndicatorRelatedMetrics,
  Metric,
  MetricTableCalculationDefinition,
  MetricTableDefinitionUpsertPayload,
  MetricTableDefinition,
  FiscalYear,
  SearchOptions,
  ValueDefinition,
  ValueDefinitionGroup,
  MetricLoaderFlags,
  MinimalDataRequest,
  DataRequestSourceStatus,
  DataRequestStatus,
  FiscalYearStatus,
  CreateMetricTableColumnDefinitionPayload,
  MetricTableColumnDefinition,
  UpdateMetricTableColumnDefinitionPayload,
  Taxonomy,
  RelatedFieldType,
  RelatedField,
  FieldRelatedFieldWithValue,
  AddFieldRelationsRequest,
  AssociateTaxonomiesRequest,
  ValueDefinitionFrequency,
  ValueDefinitionFrequencyStructure,
  BulkUpdateValueDefinitionFrequencyRequest,
  MetricFieldVisibilityPerFiscalYear,
  MetricVisibleFields,
  MetricTableCalculationTypeDetailFormulaFilter,
} from '../../../models';
import { ApiService } from '../../common/api/api.service';
import { BYPASS_INTERCEPTOR_ERROR_MANAGING } from '../../../interceptors/error-interceptor/bypass-error-constant';
import { ClientIndicatorsService } from '../client-indicators/client-indicators.service';
import { MetricApiService } from '../../types';

@Injectable({
  providedIn: 'root',
})
export class ClientMetricsService extends MetricApiService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(
    private apiService: ApiService,
    private clientIndicatorsService: ClientIndicatorsService,
  ) {
    super();
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.metrics;
  }

  public search(searchOptions: SearchOptions): Observable<ApiResponse<Metric[]>> {
    return this.clientIndicatorsService.searchMetrics(searchOptions);
  }

  getMetricDefinitions(metric_id: string): Observable<ApiResponse<ValueDefinition[]>> {
    const params = new HttpParams()
      .append('load_validators', false)
      .append('load_related_equivalent', false)
      .append('load_related_core_equivalent', false);
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/${metric_id}`, { params }).pipe(
      map((result: ApiResponse<Metric>) => {
        const value_definitions: ValueDefinition[] = [];
        for (const value_definition_group of result.data.value_definition_groups || []) {
          for (const value_definition of value_definition_group.value_definitions || []) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            value_definitions.push(value_definition);
          }
        }
        return {
          meta: result.meta,
          errors: result.errors,
          data: value_definitions,
        };
      }),
    );
  }

  listMetrics(metricIds: string[]): Observable<ApiResponse<Metric[]>> {
    let params = new HttpParams();
    metricIds.forEach((metricId) => {
      params = params.append('metric_ids', metricId);
    });
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics`, { params });
  }

  getMetric(id: string, params?: MetricLoaderFlags): Observable<ApiResponse<Metric>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/` + id, { params });
  }

  importMetric(core_metric_id: string): Observable<ApiResponse<Metric>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/import/` + core_metric_id, {});
  }

  importReferenceMetrics(core_metric_ids: string[]): Observable<ApiResponse<Metric>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/import_reference_metrics`, {
      core_metric_ids,
    });
  }

  createMetric(payload: any): Observable<ApiResponse<Metric>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/metrics`, payload);
  }

  updateMetric(metric_id: string, payload: any): Observable<ApiResponse<Metric>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/metrics/${metric_id}`, payload);
  }

  updateMetricTags(metric_id: string, tags?: string[]): Observable<ApiResponse<Metric>> {
    const payload: { [key: string]: any } = {};
    if (tags) {
      payload.tags = tags;
    }
    return this.apiService.put(`${this.servicePath}${this.resource}/metrics/${metric_id}/tags`, payload);
  }

  checkIfMetricCodeExists(metric_code: string): Observable<ApiResponse<CodeCheck>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/code_check/${metric_code}`);
  }

  getMetricFieldVisibility(metricId: string): Observable<ApiResponse<MetricFieldVisibilityPerFiscalYear[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/${metricId}/field_visibility`);
  }

  getMetricVisibleFields(metricId: string): Observable<ApiResponse<MetricVisibleFields>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/${metricId}/visible_fields`);
  }

  // Groups

  createGroup(metricID: string, payload?: any): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricID}/value_definition_groups`,
      payload,
    );
  }

  moveGroup(metricID: string, valueDefinitionGroupID: string, position: number): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricID}/value_definition_groups/${valueDefinitionGroupID}/move/${position}`,
      {},
    );
  }

  deleteGroup(metricID: string, valueDefinitionGroupId: string): Observable<ApiResponse<Metric>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricID}/value_definition_groups/${valueDefinitionGroupId}`,
    );
  }

  updateGroup(metricId: string, valueDefinitionGroupID: string, payload: any): Observable<ApiResponse<Metric>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupID}`,
      payload,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  // Fields

  createField(metricId: string, valueDefinitionGroupId: string, payload: any): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions`,
      payload,
    );
  }

  duplicateField(valueDefinition: ValueDefinition): Observable<ApiResponse<ValueDefinition>> {
    const newValueDefinition: ValueDefinition = JSON.parse(JSON.stringify(valueDefinition));
    newValueDefinition.id = uuidv4();
    const response: ApiResponse<ValueDefinition> = {
      meta: {},
      errors: [],
      data: newValueDefinition,
    };
    return of(response).pipe(delay(1000));
  }

  moveField(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    position: number,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/move/${position}`,
      {},
    );
  }

  public moveFieldFromGroup(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    position: number,
    targetValueDefinitionGroupId: string,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.valueDefinitionPath(metricId, valueDefinitionGroupId, valueDefinitionId)}/move`,
      { position, value_definition_group_id: targetValueDefinitionGroupId },
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  transferField(
    fromValueDefinition: ValueDefinition,
    toValueDefinitionGroup: ValueDefinitionGroup,
  ): Observable<ApiResponse<ValueDefinition>> {
    const newValueDefinition: ValueDefinition = JSON.parse(JSON.stringify(fromValueDefinition));
    newValueDefinition.value_definition_group_id = toValueDefinitionGroup.id;
    const response: ApiResponse<ValueDefinition> = {
      meta: {},
      errors: [],
      data: newValueDefinition,
    };
    return of(response).pipe(delay(1000));
  }

  updateField(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: any,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}`,
      payload,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  updateFieldInformation(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: FieldInformationRequest,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/field_information`,
      payload,
    );
  }

  deleteField(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    forceDelete?: boolean,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}${forceDelete ? '?force_delete=true' : ''}`,
      undefined,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  getConditionalTriggersForValueDefinition(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
  ): Observable<ApiResponse<ConditionalTrigger[]>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/conditional_triggers`,
    );
  }

  createConditionalTrigger(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: ConditionalTriggerUpsertPayload,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/conditional_triggers`,
      payload,
    );
  }

  updateConditionalTrigger(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    conditionTriggerId: string,
    payload: ConditionalTriggerUpsertPayload,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/conditional_triggers/${conditionTriggerId}`,
      payload,
    );
  }

  deleteConditionalTrigger(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    conditionTriggerId: string,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/conditional_triggers/${conditionTriggerId}`,
    );
  }

  createMetricTable(metricID: string, payload: MetricTableDefinitionUpsertPayload): Observable<ApiResponse<Metric>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/metrics/${metricID}/tables`, payload);
  }

  getMetricTable(
    metricId: string,
    tableId: string,
    checkValues = false,
  ): Observable<ApiResponse<MetricTableDefinition>> {
    const params = new HttpParams().append('check_values', checkValues);
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}`, { params });
  }

  listMetricTables(metricId: string): Observable<ApiResponse<MetricTableDefinition[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/${metricId}/tables`);
  }

  updateMetricTable(
    metricId: string,
    tableId: string,
    payload: MetricTableDefinitionUpsertPayload,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}`,
      payload,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  moveMetricTable(metricId: string, tableId: string, position: number): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/move/${position}`,
      {},
    );
  }

  deleteMetricTable(metricId: string, tableId: string): Observable<ApiResponse<Metric>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}`,
      undefined,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  createMetricTableTotal(metricId: string, tableId: string, payload: MetricTableCalculationDefinition) {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/totals`,
      payload,
    );
  }

  updateMetricTableTotal(metricId: string, tableId: string, payload: MetricTableCalculationDefinition) {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/totals`,
      payload,
    );
  }

  moveMetricTableTotal(
    metricId: string,
    tableId: string,
    totalId: string,
    position: number,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/totals/${totalId}/move/${position}`,
      {},
    );
  }

  deleteMetricTableTotal(metricId: string, tableId: string, totalId: string) {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/totals/${totalId}`,
    );
  }

  public deactivateMetricTableTotal(
    metricId: string,
    tableId: string,
    totalId: string,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/totals/${totalId}/deactivate`,
    );
  }

  public activateMetricTableTotal(metricId: string, tableId: string, totalId: string): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/totals/${totalId}/activate`,
    );
  }

  getRelatedMetrics(id: string): Observable<ApiResponse<IndicatorRelatedMetrics>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/${id}/related_metrics`);
  }

  public getTaxonomies(_id: string): Observable<ApiResponse<Taxonomy[]>> {
    return EMPTY;
  }

  updateMetricTableInformation(
    metricId: string,
    tableId: string,
    payload: FieldInformationRequest,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/field_information`,
      payload,
    );
  }

  getLatestFiscalYearWithCollectValues(
    metricId: string,
    valueDefinitionGroupIds: string[],
    valueDefinitionIds: string[],
    metricTableIds?: string[],
  ): Observable<ApiResponse<FiscalYear | null>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/latest_fiscal_year_with_values`,
      {
        value_definition_group_ids: valueDefinitionGroupIds,
        value_definition_ids: valueDefinitionIds,
        metric_table_ids: metricTableIds,
      },
    );
  }

  deactivateValueDefinition(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/deactivate`,
      {},
    );
  }

  deactivateValueDefinitionGroup(metricId: string, valueDefinitionGroupId: string): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/deactivate`,
      {},
    );
  }

  activateValueDefinitionGroup(metricId: string, valueDefinitionGroupId: string): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/activate`,
      {},
    );
  }

  deactivateMetricTable(metricId: string, metricTableId: string): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${metricTableId}/deactivate`,
      {},
    );
  }

  activateMetricTable(
    metricId: string,
    metricTableId: string,
    ignoreTaxonomy: boolean = false,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${metricTableId}/${ignoreTaxonomy ? 'ignore_taxonomy' : 'activate'}`,
    );
  }

  activateValueDefinition(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    ignoreTaxonomy: boolean = false,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/${ignoreTaxonomy ? 'ignore_taxonomy' : 'activate'}`,
    );
  }

  public listMetricDataRequests(metricId: string): Observable<ApiResponse<MinimalDataRequest[]>> {
    const params = new HttpParams().appendAll({
      data_request_source_status: [DataRequestSourceStatus.NOT_STARTED, DataRequestSourceStatus.STARTED],
      data_request_status: [DataRequestStatus.DRAFT, DataRequestStatus.ACTIVE],
      fiscal_year_status: FiscalYearStatus.OPEN,
    });

    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/${metricId}/minimal_data_requests`, {
      params,
    });
  }

  public createMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    payload: CreateMetricTableColumnDefinitionPayload,
    forceDelete?: boolean,
  ): Observable<ApiResponse<MetricTableColumnDefinition>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/column_definitions${forceDelete ? '?force_delete=true' : ''}`,
      payload,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  public deleteMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    columnId: string,
    forceDelete?: boolean,
  ): Observable<ApiResponse<MetricTableColumnDefinition>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/column_definitions/${columnId}${forceDelete ? '?force_delete=true' : ''}`,
      {},
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  public deleteMetricTableColumnOption(
    metricId: string,
    tableId: string,
    columnId: string,
    optionId: string,
  ): Observable<undefined> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/column_definitions/${columnId}/options/${optionId}`,
      {},
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  public updateMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    columnId: string,
    payload: UpdateMetricTableColumnDefinitionPayload,
  ): Observable<ApiResponse<MetricTableColumnDefinition>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/column_definitions/${columnId}`,
      payload,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  public moveMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    columnId: string,
    position: number,
  ): Observable<ApiResponse<MetricTableColumnDefinition>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/column_definitions/${columnId}/move/${position}`,
      {},
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  public publishMetric(): Observable<ApiResponse<Metric>> {
    return EMPTY;
  }

  public publishField(): Observable<ApiResponse<Metric>> {
    return EMPTY;
  }

  public publishMetricTable(): Observable<ApiResponse<Metric>> {
    return EMPTY;
  }

  public getFieldTaxonomies(
    metricId: string,
    vdgId: string,
    vdId: string,
    completeFrameworks = false,
    frequency_code?: string,
    order_by?: string,
    order_by_direction?: 'asc' | 'desc',
  ): Observable<ApiResponse<Taxonomy[]>> {
    let params = new HttpParams().append('complete_frameworks', completeFrameworks);
    if (order_by) {
      params = params.append('order_by', order_by);
    }
    if (order_by_direction) {
      params = params.append('order_by_direction', order_by_direction);
    }
    if (frequency_code) {
      params = params.append('frequency_code', frequency_code);
    }

    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${vdgId}/value_definitions/${vdId}/taxonomies`,
      { params },
    );
  }

  public getMetricTableTaxonomies(
    metricId: string,
    metricTableDefinitionId: string,
    completeFrameworks = false,
    frequencyCode?: string,
    order_by?: string,
    order_by_direction?: 'asc' | 'desc',
  ): Observable<ApiResponse<Taxonomy[]>> {
    let params = new HttpParams().append('complete_frameworks', completeFrameworks);
    if (order_by) {
      params = params.append('order_by', order_by);
    }
    if (order_by_direction) {
      params = params.append('order_by_direction', order_by_direction);
    }
    if (frequencyCode) {
      params = params.append('frequency_code', frequencyCode);
    }

    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${metricTableDefinitionId}/taxonomies`,
      { params },
    );
  }

  public deleteTaxonomy(_metricId: string, _taxonomyId: string): Observable<undefined> {
    return EMPTY;
  }

  public deleteFieldTaxonomy(
    _metricId: string,
    _valueDefinitionGroupId: string,
    _valueDefinitionId: string,
    _taxonomyId: string,
  ): Observable<undefined> {
    return EMPTY;
  }

  public deleteMetricTableTaxonomy(
    _metricId: string,
    _metricTableDefinitionId: string,
    _taxonomyId: string,
  ): Observable<undefined> {
    return EMPTY;
  }

  public addFieldRelatedFields(
    _metricId: string,
    _valueDefinitionGroupId: string,
    _valueDefinitionId: string,
    _payload: AddFieldRelationsRequest,
  ): Observable<ApiResponse<RelatedField[]>> {
    return EMPTY;
  }

  public addMetricTableRelatedFields(
    _metricId: string,
    _metricTableDefinitionId: string,
    _payload: AddFieldRelationsRequest,
  ): Observable<ApiResponse<RelatedField[]>> {
    return EMPTY;
  }

  public deleteFieldRelatedFields(
    _metricId: string,
    _valueDefinitionGroupId: string,
    _valueDefinitionId: string,
    _relatedFieldId: string,
  ): Observable<undefined> {
    return EMPTY;
  }

  public deleteMetricTableRelatedFields(
    _metricId: string,
    _metricTableDefinitionId: string,
    _relatedFieldId: string,
  ): Observable<undefined> {
    return EMPTY;
  }

  public getFieldRelatedFields(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    relatedFieldType?: RelatedFieldType,
  ): Observable<ApiResponse<RelatedField[]>> {
    let params = new HttpParams();
    if (relatedFieldType) {
      params = params.append('relation_type', relatedFieldType);
    }

    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/field_relations`,
      { params },
    );
  }

  public getMetricTableRelatedFields(
    metricId: string,
    metricTableDefinitionId: string,
    relatedFieldType?: RelatedFieldType,
  ): Observable<ApiResponse<RelatedField[]>> {
    let params = new HttpParams();
    if (relatedFieldType) {
      params = params.append('relation_type', relatedFieldType);
    }

    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${metricTableDefinitionId}/field_relations`,
      { params },
    );
  }

  public getFieldRelatedFieldsWithValues(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    business_unit_id: string,
    frequency_code: string,
    relatedFieldType?: RelatedFieldType,
  ): Observable<ApiResponse<FieldRelatedFieldWithValue[]>> {
    let params = new HttpParams();
    params = params.append('business_unit_id', business_unit_id);
    params = params.append('frequency_code', frequency_code);
    if (relatedFieldType) {
      params = params.append('relation_type', relatedFieldType);
    }

    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/field_relations_with_values`,
      { params },
    );
  }

  public addTaxonomies(_metricId: string, _payload: AssociateTaxonomiesRequest): Observable<ApiResponse<Taxonomy[]>> {
    return EMPTY;
  }

  public addFieldTaxonomies(
    _metricId: string,
    _valueDefinitionGroupId: string,
    _valueDefinitionId: string,
    _payload: AssociateTaxonomiesRequest,
  ): Observable<ApiResponse<Taxonomy[]>> {
    return EMPTY;
  }

  public addMetricTableTaxonomies(
    _metricId: string,
    _metricTableDefinitionId: string,
    _payload: AssociateTaxonomiesRequest,
  ): Observable<ApiResponse<Taxonomy[]>> {
    return EMPTY;
  }

  listValueDefinitionFrequencies(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
  ): Observable<ApiResponse<ValueDefinitionFrequencyStructure[]>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/frequencies`,
    );
  }

  bulkUpdateValueDefinitionFrequencies(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    bulkUpdateRequest: BulkUpdateValueDefinitionFrequencyRequest,
  ): Observable<ApiResponse<ValueDefinitionFrequency[]>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/frequencies`,
      bulkUpdateRequest,
    );
  }

  private basePath() {
    return `${this.servicePath}${this.resource}`;
  }

  private metricPath(metricId: string) {
    return `${this.basePath()}/metrics/${metricId}`;
  }

  private valueDefinitionGroupPath(metricId: string, valueDefinitionGroupId: string) {
    return `${this.metricPath(metricId)}/value_definition_groups/${valueDefinitionGroupId}`;
  }

  private valueDefinitionPath(metricId: string, valueDefinitionGroupId: string, valueDefinitionId: string) {
    return `${this.valueDefinitionGroupPath(metricId, valueDefinitionGroupId)}/value_definitions/${valueDefinitionId}`;
  }

  validateFormulaVariables(formula: string): Observable<ApiResponse<void, Error>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/validate_formula`,
      {
        formula,
      },
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }
  validateTableTotalFormulaVariables(
    columns: string[],
    rows: MetricTableCalculationTypeDetailFormulaFilter[],
  ): Observable<ApiResponse<void, Error>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/validate_table_formula`,
      {
        columns,
        rows,
      },
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }
}
