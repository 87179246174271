import { Entity } from '../entities';

export interface FiscalYear extends Entity {
  year: number;
  start: string;
  end: string;
  id: string;
  status: FiscalYearStatus;
  frequency_code?: string;
  framework_taxonomy_status?: FiscalYearFrameworkTaxonomyStatus;
}

export interface FiscalYearsFilteringOptions {
  offset?: number;
  limit?: number;
  statuses?: FiscalYearStatus[];
  with_published_draft?: boolean;
  taxonomy_id?: string;
}

export interface FiscalYearRequest {
  frequency_code: number;
  start: Date | string;
  end: Date | string;
  status: FiscalYearStatus;
  framework_taxonomy_status?: FiscalYearFrameworkTaxonomyStatus;
}

export interface FiscalYearCreateRegularRequest {
  frequency_code: number;
  start: Date | string;
  end: Date | string;
  status: FiscalYearStatus;
}

export enum FiscalYearStatus {
  OPEN = 'open',
  CLOSED = 'closed',
  DRAFT = 'draft',
}

export enum FiscalYearFrameworkTaxonomyStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  QUEUED = 'queued',
  IN_PROGRESS = 'in_progress',
  ERROR = 'error',
}

export type FiscalYearTemplateRow = FiscalYear & { item?: FiscalYear };

export interface FiscalYearTable {
  rows: FiscalYearTemplateRow[];
  size: number;
}

export interface FiscalYearNotesRequest {
  notes: string | null;
}

export interface MetricsMappingCount {
  source_name: string;
  metric_count: number;
}

export interface FiscalYearClosingValidation {
  metrics_mapping_count: MetricsMappingCount[];
  data_request_count: number;
}

export interface FiscalYearPeriod extends Entity {
  start: string;
  end: string;
  period: Period;
  fiscal_year_id: string;
  period_reference?: Months;
}

export enum Period {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
  M1 = 'M1',
  M2 = 'M2',
  M3 = 'M3',
  M4 = 'M4',
  M5 = 'M5',
  M6 = 'M6',
  M7 = 'M7',
  M8 = 'M8',
  M9 = 'M9',
  M10 = 'M10',
  M11 = 'M11',
  M12 = 'M12',
}

export enum Months {
  january = 'january',
  february = 'february',
  march = 'march',
  april = 'april',
  may = 'may',
  june = 'june',
  july = 'july',
  august = 'august',
  september = 'september',
  october = 'october',
  november = 'november',
  december = 'december',
}
