@if (control) {
  <div
    libFormField
    [size]="size"
    [required]="required"
    [control]="control"
    [labelPosition]="labelPosition"
    [dataTestId]="'tree-select-input'"
    [class]="'p-field'"
  >
    <lib-form-field-label [label]="label" [control]="control" [id]="_labelId"></lib-form-field-label>
    <p-treeSelect
      #treeselect
      [ariaLabelledBy]="_labelId"
      [options]="options"
      [formControl]="control"
      [id]="_labelId"
      [filter]="filter"
      [filterBy]="filterBy"
      [showClear]="showClear"
      [required]="required"
      [propagateSelectionDown]="false"
      [propagateSelectionUp]="false"
      [selectionMode]="selectionMode"
      [metaKeySelection]="false"
      [appendTo]="'body'"
      [ngClass]="{ invalid: control.invalid && (control.dirty || control.touched), disabled: control.disabled }"
      (onHide)="setToTouched()"
      [filterInputAutoFocus]="true"
      [autofocus]="autofocus"
      (onFocus)="onFocus()"
      [placeholder]="placeholder"
    ></p-treeSelect>
    @if (fixedSubscript || hint || (control.errors | errorKeys)?.length) {
      <div class="subscript-wrapper">
        @if ((hint && control.valid) || control.untouched) {
          <p class="message">
            {{ hint }}
          </p>
        }
        @if (control.dirty || control.touched) {
          @for (error of control.errors | errorKeys; track error) {
            <p id="error-message" class="p-error message">
              {{ errorMessages[error] }}
            </p>
          }
        }
      </div>
    }
  </div>
}
