import { Component, Input, OnInit } from '@angular/core';

import { combineLatestWith, map, Observable, takeUntil } from 'rxjs';
import { ActionItem, Entity, OptionList, OptionListItem, PresentationV2 } from '../../models';
import { OptionListResourceListsStore } from './option-list-resource-lists.store';
import { provideComponentStore } from '@ngrx/component-store';
import { OptionListResourceListsForm } from './option-list-resource-lists-form';
import { OptionListStore } from '../option-list/option-list.store';
import { OptionListsApiService } from '../../services/api-services';

interface Data {
  isLoading: boolean;
  resourceListItems: Record<string, ActionItem<OptionListItem>>;
  resourceLists: OptionList[];
}

@Component({
  selector: 'lib-option-list-resource-lists',
  templateUrl: './option-list-resource-lists.component.html',
  styleUrls: ['./option-list-resource-lists.component.scss'],
  providers: [provideComponentStore(OptionListResourceListsStore)],
})
export class OptionListResourceListsComponent implements OnInit {
  @Input({ required: true }) optionList!: OptionList;
  @Input({ required: true }) optionListItemsCount!: number;

  public readonly ePresentationV2 = PresentationV2;

  public data$?: Observable<Data>;
  public form = new OptionListResourceListsForm();

  constructor(
    private readonly optionListsApiService: OptionListsApiService,
    private readonly optionListStore: OptionListStore,
    private readonly optionListResourceListsStore: OptionListResourceListsStore,
  ) {}

  public ngOnInit(): void {
    this.optionListResourceListsStore.initialize(this.optionList);
    this.data$ = this.optionListResourceListsStore.resourceLists$.pipe(
      combineLatestWith(
        this.optionListResourceListsStore.resourceListItems$,
        this.optionListResourceListsStore.isLoading$,
      ),
      map(([resourceLists, resourceListItems, isLoading]) => ({
        isLoading,
        resourceListItems,
        resourceLists,
      })),
    );

    this.form.controls.resourceList.valueChanges
      .pipe(takeUntil(this.optionListResourceListsStore.destroy$))
      .subscribe((resourceList) => {
        if (resourceList) {
          this.form.controls.resourceListItems.setValue([]);
          this.optionListResourceListsStore.updateResourceListState(resourceList);
        }
      });
  }

  public bindOptionLabelFn(entity: Entity): string {
    return entity.name;
  }

  public bulkAdd(resourceListItems: Record<string, ActionItem<OptionListItem>>): void {
    const payload = this.form.toModel(resourceListItems);
    this.optionListsApiService.bulkAddOptionListItems(this.optionList.id, payload).subscribe(() => {
      this.form.controls.resourceListItems.setValue([]);
      this.optionListStore.fetchOptionListItems();
    });
  }

  public compareFn(entityA?: Entity, entityB?: Entity): boolean {
    return Boolean(entityA && entityB && entityA.id === entityB.id);
  }
}
