import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  OnInit,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TieredMenu } from 'primeng/tieredmenu';
import { ButtonProps } from '../../search/models';
import { ActionItem } from '../../models';

@Component({
  selector: 'lib-filter-v2',
  templateUrl: './filter-v2.component.html',
  styleUrl: './filter-v2.component.scss',
})
export class FilterV2Component implements OnChanges, OnInit, OnDestroy {
  @Input() buttonProps?: ButtonProps;
  @Input() items: ActionItem[] = [];
  @Input() activeActionItem?: ActionItem;
  @Input() defaultValue?: ActionItem;
  @ViewChild('menu') menu!: TieredMenu;

  @Output() optionSelected = new EventEmitter<ActionItem>();

  public itemsWithActiveStatus: MenuItem[] = [];
  public buttonTitle?: string = '';
  private overlayElement: HTMLElement | null = null;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.overlayElement = this.renderer.createElement('div');
    this.renderer.addClass(this.overlayElement, 'filter-overlay-v2');
    this.renderer.setStyle(this.overlayElement, 'display', 'none');
    this.renderer.appendChild(document.body, this.overlayElement);
  }

  ngOnDestroy() {
    if (this.overlayElement) {
      this.renderer.removeChild(document.body, this.overlayElement);
    }
  }

  ngOnChanges(): void {
    const menuItems: MenuItem[] = this.items.map((actionItem) => this.convertActionItemToMenuItem(actionItem));
    this.itemsWithActiveStatus = this.setActiveMenuItems(menuItems, this.activeActionItem?.id);
    this.buttonTitle =
      this.defaultValue?.id === this.activeActionItem?.id ? this.buttonProps?.title : this.activeActionItem?.title;
  }

  onMenuToggle(event: any) {
    if (this.overlayElement) {
      if (event.visible) {
        // Show overlay when menu opens
        this.renderer.setStyle(this.overlayElement, 'display', 'block');
        this.renderer.setStyle(document.body, 'cursor', 'pointer');

        // Add click handler to overlay
        this.renderer.listen(this.overlayElement, 'click', () => {
          this.menu.hide();
        });
      } else {
        // Hide overlay when menu closes
        this.renderer.setStyle(this.overlayElement, 'display', 'none');
        this.renderer.removeStyle(document.body, 'cursor');
      }
    }
  }

  private convertActionItemToMenuItem(actionItem: ActionItem): MenuItem {
    if (actionItem.isSeparator) {
      return { separator: true };
    }

    return {
      id: actionItem.id,
      items: actionItem.children?.map((actionItem: ActionItem) => this.convertActionItemToMenuItem(actionItem)),
      label: actionItem.title,
      icon: actionItem.icon,
      command: () => {
        this.optionSelected.emit(actionItem);
        this.menu.hide();
      },
    };
  }

  private setActiveMenuItems(menuItems: MenuItem[], activeId?: string): MenuItem[] {
    const { updatedItems } = this.updateItems(menuItems, activeId);
    return updatedItems;
  }

  private updateItems(items: MenuItem[], activeId?: string): { updatedItems: MenuItem[]; isActive: boolean } {
    let isAnyChildActive = false;

    const updatedItems = items.map((item) => {
      const { updatedItems: childItems, isActive: isChildActive } = item.items
        ? this.updateItems(item.items, activeId)
        : { updatedItems: undefined, isActive: false };

      const isActive = item.id === activeId || isChildActive;

      isAnyChildActive ||= isActive;

      return {
        ...item,
        items: childItems,
        active: isActive,
      };
    });

    return { updatedItems, isActive: isAnyChildActive };
  }
}
