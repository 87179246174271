<div class="panel-properties-content-header mh-2">
  <div class="panel-properties-content-header-title">
    <h4 class="mat-subtitle-2">{{ "Repeatable Group Export Configuration" | translate }}</h4>
  </div>
  <div class="panel-properties-content-header-actions"></div>
</div>

<div class="panel-properties-content-container">
  @if (valueDefinitions.length > 0) {
    <div class="formfield large">
      <div class="fx-row align-center">
        <div class="caption4">
          {{ "Define Fields as Context rather than Input" | translate }}
        </div>
        <mat-icon
          svgIcon="info"
          [matTooltip]="
            'All fields are considered Input unless defined as Context. Defining this allows for a table to be constructed when exporting the fields.'
              | translate
          "
          class="tip-icon color-grey-800 ml-1"
        ></mat-icon>
      </div>
      @if (!singleInput) {
        <div class="grid grid-2 grid-auto-rows mt-5">
          <div class="bold-label">{{ "Fields" | translate }}</div>
          <div class="bold-label pull right">{{ "Defined as Context" | translate }}</div>
        </div>
      }
      @if (!singleInput) {
        <lib-divider class="mt-2"></lib-divider>
      }
      @for (valueDefinition of valueDefinitions; track valueDefinition) {
        <div>
          @if (singleInput) {
            <div class="fx-row align-center mt-4">
              <lib-slide-toggle
                [checked]="valueDefinition.context"
                (sliderToggle)="updateContextField($event, valueDefinition)"
                [disabled]="!valueDefinition.context"
                [attr.data-testid]="'single-vd-toggle-' + valueDefinition.id"
                [attr.title]="
                  (valueDefinition | disableContextSwitch: valueDefinitions) ? contextSwitchDisabledMsg : ''
                "
              ></lib-slide-toggle>
              <div class="ml-2">
                <div class="label single-value-definition-input-label" [title]="valueDefinition.label">
                  {{ valueDefinition.label }}
                </div>
              </div>
            </div>
          }
          @if (!singleInput) {
            <div class="fx-row align-center mt-4">
              <div class="label multiple-value-definition-input-label" [title]="valueDefinition.label">
                {{ valueDefinition.label }}
              </div>
              <div class="pull right">
                <lib-slide-toggle
                  [disabled]="
                    (isMetricUpdating$ | async) ||
                      (valueDefinition | disableContextSwitch: valueDefinitions) ||
                      (valueDefinitionGroup?.core_value_definition_group_id &&
                        enableRefMetricsV2 &&
                        xbrlExportEnabled &&
                        metric.category === eMetricCategory.REFERENCE) | boolean
                  "
                  [attr.data-testid]="'multi-vd-toggle-' + valueDefinition.id"
                  [checked]="valueDefinition.context"
                  (sliderToggle)="updateContextField($event, valueDefinition)"
                  [attr.title]="
                    (valueDefinition | disableContextSwitch: valueDefinitions) ? contextSwitchDisabledMsg : ''
                  "
                ></lib-slide-toggle>
              </div>
            </div>
          }
        </div>
      }
    </div>
  }

  @if (valueDefinitions.length === 0) {
    <lib-empty-results class="mt-4" [displayData]="noFieldsInTheGroup"></lib-empty-results>
  }
</div>
