import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import { MentionItem } from '../../../models';
import { StyledMentionBlot } from './mention-blot';

@Component({
  selector: 'lib-mention',
  templateUrl: './mention.component.html',
  styleUrl: './mention.component.scss',
  providers: [StyledMentionBlot],
})
export class MentionComponent {
  @Input() control?: FormControl;
  @Input() userMentionItems: MentionItem[] = [];
  @Input() currentUserId: string = '';
  @Input() hint: string = '';
  @Input() menuOrientation: 'top' | 'bottom' = 'bottom';
  @Input() placeholder: string = '';
  @Input() size: 'small' | 'large' = 'large';

  @ViewChild('editor') editor?: QuillEditorComponent;

  modules = {
    mention: {
      mentionDenotationChars: ['@'],
      blotName: 'styled-mention',
      allowedChars: /^[A-Za-z\s]*$/,
      defaultMenuOrientation: this.menuOrientation,
      source: (searchTerm: string, renderList: (data: any[], searchTerm: string) => void) => {
        const matches = this.userMentionItems.filter((user) =>
          user.value.toLowerCase().startsWith(searchTerm.toLowerCase()),
        );
        renderList(matches, searchTerm);
      },
      renderItem: (item: MentionItem) => {
        const div = document.createElement('div');
        div.setAttribute('class', 'user-tag-container');
        div.innerHTML = `
          <div class="initials">${item.firstName.charAt(0).toUpperCase() ?? ''}${item.lastName.charAt(0).toUpperCase() ?? ''}</div>
          <div class="details">
            <p>${item.menuDisplayName}</p>
            <p class="subtitle">${item.email}</p>
          </div>`;
        return div;
      },
      positioningStrategy: 'fixed',
      dataAttributes: ['id', 'value', 'isCurrentUser', 'firstName', 'lastName', 'email', 'menuDisplayName'],
    },
    toolbar: false,
  };
}
