import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { finalize, switchMap } from 'rxjs/operators';
import {
  ActionItem,
  ApiError,
  ApiResponse,
  CalculatedTypeDetails,
  DeactivateEntityTypes,
  Metric,
  MetricCategory,
  NumberTypeDetails,
  ResourceType,
  Status,
  Unit,
  ValueDefinition,
  ValueDefinitionDisplayType,
  ValueDefinitionGroup,
  ValueDefinitionType,
} from '../../../../models';

import { TranslateService } from '../../../../services/common';
import { SearchService } from '../../../../search';
import { ValueDefinitionFormat } from '../../../models';
import { ValueDefinitionTemplateType } from '../../../../models';
import { MetricStructureStateService } from '../../../services/metric-structure-state.service';
import { UntypedFormControl } from '@angular/forms';
import { ConfirmationDialogComponent, DialogsService } from '../../../../dialogs';
import { HttpErrorResponse } from '@angular/common/http';
import { MetricApiService } from '../../../../services/types';
import { DeactivateEntityService } from '../../../services/deactivate-entity/deactivate-entity.service';
import { ActivateEntityService } from '../../../services/activate-entity/activate-entity.service';
import { ValueDefinitionUtils } from '../../../classes/ValueDefinitionUtils/value-definition-utils';
import { ErrorManagerService } from '../../../../error-manager';
import { FeatureFlagService } from '../../../../feature-flag';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'lib-metric-structure-field-form',
  templateUrl: './metric-structure-field-form.component.html',
  styleUrls: ['./metric-structure-field-form.component.scss'],
})
export class MetricStructureFieldFormComponent implements OnInit {
  @Input({ required: true }) valueDefinition!: ValueDefinition;
  @Input({ required: true }) valueDefinitionGroup!: ValueDefinitionGroup;
  @Input() vdIndex: number = 0;
  @Input() isActive: boolean = false;

  @ViewChild('field') field?: ElementRef<HTMLDivElement>;

  readonly eMetricCategory: typeof MetricCategory = MetricCategory;
  readonly eValueDefinitionType: typeof ValueDefinitionDisplayType = ValueDefinitionDisplayType;
  readonly isAdmin: boolean = this.metricStructureService.isAdmin;

  metric?: Metric;

  units: ActionItem<Unit>[] = [];

  dummyControl = new UntypedFormControl();
  public deactivationEnabled: boolean = false;

  constructor(
    private translateService: TranslateService,
    private metricStructureService: MetricStructureStateService,
    private searchService: SearchService,
    private metricsService: MetricApiService,
    private dialogsService: DialogsService,
    private errorManagerService: ErrorManagerService,
    private deactivateEntityService: DeactivateEntityService,
    private activateEntityService: ActivateEntityService,
    private featureFlagService: FeatureFlagService,
  ) {}

  ngOnInit(): void {
    this.deactivationEnabled = this.featureFlagService.areAnyFeatureFlagsEnabled([
      'metric_structure_deactivation_enabled',
    ]);
    this.searchService.searchResources(ResourceType.unit).subscribe((units) => {
      this.units = units;
    });
    this.metricStructureService.metric$.subscribe((metric) => {
      this.metric = metric;
    });
    if (this.isActive) {
      setTimeout(() => {
        this.field?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 0);
    }
  }

  public setSelectedVd(vd?: ValueDefinition): void {
    this.metricStructureService.updateSelectedItem(vd);
  }

  private updateFieldStatus(updating: boolean): void {
    this.metricStructureService.updateIsMetricUpdating(updating);
  }

  public isElementTriggered(element: ValueDefinition): boolean {
    return element.conditional_triggers ? element.conditional_triggers.length > 0 : false;
  }

  public getValueDefinitionFormat(valueDefinition: ValueDefinition): ValueDefinitionFormat {
    return ValueDefinitionUtils.getValueDefinitionFormat(valueDefinition);
  }

  public isValueDefinitionTrigger(valueDefinition: ValueDefinition): boolean {
    return (
      this.metric?.value_definition_groups?.some((vdg) => {
        if (vdg.conditional_triggers?.some((ct) => ct.source_value_definition_id == valueDefinition.id)) {
          return true;
        }
        return !!vdg.value_definitions?.some((vd) =>
          vd.conditional_triggers?.some((ct) => ct.source_value_definition_id == valueDefinition.id),
        );
      }) ?? false
    );
  }

  public deleteField(event: MouseEvent, valueDefinitions: ValueDefinition[], index: number): void {
    event.stopPropagation();
    if (valueDefinitions) {
      const valueDefinition: ValueDefinition = valueDefinitions[index];
      if (valueDefinition) {
        this.dialogsService
          .open(ConfirmationDialogComponent, {
            data: {
              title: this.translateService.instant('Delete field'),
              warningMsg: this.translateService.instant(
                valueDefinition.type === ValueDefinitionType.calculated
                  ? 'Please note that deleting this calculated field will also delete any stored calculated values. The data from the variables in the formula are untouched.'
                  : 'Are you sure you wish to delete this field? All the data associated with it will be lost.',
              ),
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result?.status === Status.CONFIRMED) {
              this.completeFieldDeletion(valueDefinition, valueDefinitions, index);
            }
          });
      }
    }
  }

  private handleFieldDeactivation(): void {
    this.deactivateEntityService.deactivate(
      DeactivateEntityTypes.FIELD,
      this.metric?.id ?? '',
      this.valueDefinition,
      this.valueDefinitionGroup,
    );
  }

  public deactivateField(): void {
    if (!this.isAdmin || (this.isAdmin && !this.metric?.reference_v2)) {
      this.handleFieldDeactivation();
      return;
    }
    this.dialogsService
      .open(ConfirmationDialogComponent, {
        data: {
          title: this.translateService.instant('Deactivate field'),
          warningMsg: this.translateService.instant(
            'Deactivated fields will sync with Platform tonight, deactivating them in customer environments. Platform users cannot activate fields deactivated in Core.',
          ),
          primaryBtn: this.translateService.instant('Deactivate'),
        },
      })
      .afterClosed()
      .subscribe({
        next: (result) => {
          if (result?.status === Status.CONFIRMED) {
            this.handleFieldDeactivation();
          }
        },
        error: (errorResponse: unknown) => {
          this.errorManagerService.handleError(errorResponse as HttpErrorResponse);
        },
      });
  }

  private completeFieldDeletion(valueDefinition: ValueDefinition, valueDefinitions: ValueDefinition[], index: number) {
    this.setSelectedVd(undefined);
    if (valueDefinition.id !== ValueDefinitionTemplateType.template) {
      this.updateFieldStatus(true);
      this.metricsService
        .deleteField(this.metric?.id as string, valueDefinition.value_definition_group_id, valueDefinition.id)
        .pipe(finalize(() => this.updateFieldStatus(false)))
        .subscribe({
          next: () => {
            valueDefinitions.splice(index, 1);
            this.metricStructureService.updateSelectedItem(undefined);
          },
          error: (errorResponse: unknown) => {
            try {
              const error: ApiError<string> = (
                ((errorResponse as HttpErrorResponse).error as ApiResponse).errors as ApiError<string>[]
              )[0];

              if (error.type === 'value_definition_has_comments_error') {
                this.handleDeletionOfFieldWithComments(valueDefinition, valueDefinitions, index);
              } else {
                this.metricStructureService.handleDeleteValidationErrors(errorResponse as HttpErrorResponse);
              }
            } catch (_) {
              this.errorManagerService.handleError(errorResponse as HttpErrorResponse);
            }
          },
        });
    }
  }

  public getUnitSymbol(typeDetails: NumberTypeDetails | CalculatedTypeDetails): string {
    const symbol: ActionItem | undefined = this.units.find(
      (unit) => unit.item?.family === typeDetails.family && unit.item?.code === typeDetails.units,
    );
    return symbol?.item.symbol || symbol?.item.code;
  }

  public getTipItem(id?: string): ActionItem | undefined {
    return this.metricStructureService.tipDisplayOptions.find((displayOption) => displayOption.id === id);
  }

  activateField(): void {
    this.activateEntityService.activateValueDefinition(this.metric?.id ?? '', this.valueDefinition).subscribe();
  }

  private handleDeletionOfFieldWithComments(
    valueDefinition: ValueDefinition,
    valueDefinitions: ValueDefinition[],
    index: number,
  ) {
    this.dialogsService
      .open(ConfirmationDialogComponent, {
        data: {
          title: this.translateService.instant('Field contains comments in request'),
          warningMsg: this.translateService.instant(
            'The field you’re trying to delete contains comments in a request. Are you sure you want to delete the field?',
          ),
          primaryBtn: this.translateService.instant('Yes'),
          secondaryBtn: this.translateService.instant('No'),
        },
      })
      .afterClosed()
      .pipe(
        switchMap((res) =>
          res?.status === Status.CONFIRMED
            ? this.metricsService.deleteField(
                this.metric?.id as string,
                valueDefinition.value_definition_group_id,
                valueDefinition.id,
                true,
              )
            : EMPTY,
        ),
        finalize(() => this.updateFieldStatus(false)),
      )
      .subscribe({
        next: () => {
          valueDefinitions.splice(index, 1);
          this.metricStructureService.updateSelectedItem(undefined);
        },
        error: (errorResponse: unknown) => {
          this.metricStructureService.handleDeleteValidationErrors(errorResponse as HttpErrorResponse);
        },
      });
  }
}
