import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

export enum ImportReferenceCoreMetricsStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

export enum DataRequestActivationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface DataRequestActivationUpdate {
  dataRequestId: string;
  status: DataRequestActivationStatus;
}

@Injectable({
  providedIn: 'root',
})
export class WebsocketMessagingService {
  private _platformImportReferenceCoreMetricsStatus$: BehaviorSubject<ImportReferenceCoreMetricsStatus> =
    new BehaviorSubject<ImportReferenceCoreMetricsStatus>(ImportReferenceCoreMetricsStatus.COMPLETE);

  platformImportReferenceCoreMetricsStatus$: Observable<ImportReferenceCoreMetricsStatus> =
    this._platformImportReferenceCoreMetricsStatus$.asObservable();

  private _dataRequestActivationStatusMap$: BehaviorSubject<Map<string, DataRequestActivationStatus>> =
    new BehaviorSubject<Map<string, DataRequestActivationStatus>>(new Map());

  dataRequestActivationStatusMap$: Observable<Map<string, DataRequestActivationStatus>> =
    this._dataRequestActivationStatusMap$.asObservable();

  setPlatformImportReferenceCoreMetricsStatus(status: ImportReferenceCoreMetricsStatus): void {
    this._platformImportReferenceCoreMetricsStatus$.next(status);
  }

  setDataRequestActivationStatus(dataRequestId: string, status: DataRequestActivationStatus): void {
    const currentMap = this._dataRequestActivationStatusMap$.getValue();
    const newMap = new Map(currentMap);
    newMap.set(dataRequestId, status);
    this._dataRequestActivationStatusMap$.next(newMap);
  }

  resetDataRequestActivationStatus(dataRequestId: string): void {
    const currentMap = this._dataRequestActivationStatusMap$.getValue();
    const newMap = new Map(currentMap);
    newMap.delete(dataRequestId);
    this._dataRequestActivationStatusMap$.next(newMap);
  }

  getDataRequestActivationStatus(dataRequestId: string): Observable<DataRequestActivationStatus | undefined> {
    return this.dataRequestActivationStatusMap$.pipe(map((statusMap) => statusMap.get(dataRequestId)));
  }
}
