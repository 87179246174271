<div [ngClass]="'actionable-card ' + cardSize">
  @if (displayDefaultHeader) {
    <div class="header">
      <p class="title">
        <span class="title-text" libTitleOnEllipsis [tolerance]="1">{{ item?.title }}</span>
      </p>
      @if (withExternalLink) {
        <mat-icon
          class="external-link color-info-600 icon-lg"
          svgIcon="external-link"
          (click)="externalLinkClick.emit(item)"
          data-testid="external-link"
        ></mat-icon>
      }
    </div>
  }
  <ng-content></ng-content>
</div>
