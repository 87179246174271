<p-table [value]="dummyValues">
  <ng-template pTemplate="header">
    <tr>
      @for (column of columns; track column.name) {
        <th class="skeleton-table-header">{{ column.name }}</th>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-value>
    <tr>
      @for (row of [].constructor(columns.length); let index = $index; track index) {
        <td><p-skeleton height="2.5rem" /></td>
      }
    </tr>
  </ng-template>
</p-table>
