<lib-dialog-title (closeEvent)="closeDialog()" [title]="'Saving Error' | translate"> </lib-dialog-title>

<div mat-dialog-content class="error-dialog">
  <p>
    {{
      data.errorMessage ||
        ("Your latest changes to {valueLabel} may not have been saved. Copy any unsaved data to your clipboard to avoid data loss."
          | translate: { valueLabel: data.valueLabel })
    }}
  </p>
  <p>
    {{ "Click " | translate }}
    <b>{{ "Confirm" | translate }}</b>
    {{
      " to refresh the page. Unsaved data will be lost. Otherwise, exit this message to copy unsaved data before manually refreshing the page."
        | translate
    }}
  </p>
</div>

<div mat-dialog-actions>
  <button lib-button buttonType="primary" [label]="'Confirm' | translate" (click)="refresh()"></button>

  <button
    lib-button
    buttonType="cancel_with_border"
    class="btn ml-3"
    [label]="'Cancel' | translate"
    (click)="closeDialog()"
  ></button>
</div>
