import { Component, Input } from '@angular/core';
import { TableColumn } from '../../../models';

@Component({
  selector: 'lib-skeleton-table',
  templateUrl: './skeleton-table.component.html',
  styleUrl: './skeleton-table.component.scss',
})
export class SkeletonTableComponent {
  @Input({ required: true }) columns: TableColumn<any>[] = [];
  @Input()
  set numberOfRow(value: number) {
    this.dummyValues = Array.from({ length: value }, () => ({}));
  }

  dummyValues: any[] = [];
}
