<button
  lib-button
  [ngClass]="{ 'active filter-button-color': !buttonProps?.disabled }"
  [disabled]="!!buttonProps?.disabled"
  (click)="menu.toggle($event)"
>
  <span>
    <mat-icon class="icon-sm mr-1" [svgIcon]="buttonProps?.icon ?? 'filter'"></mat-icon>
  </span>
  <div class="mat-body-1">{{ buttonTitle }}</div>
</button>

<p-tieredMenu
  #menu
  [model]="itemsWithActiveStatus"
  [popup]="true"
  appendTo="body"
  [autoZIndex]="true"
  [baseZIndex]="800"
  (onShow)="onMenuToggle({ visible: true })"
  (onHide)="onMenuToggle({ visible: false })"
>
  <ng-template pTemplate="item" let-item let-hasSubmenu="hasSubmenu">
    <a
      pRipple
      class="filter-option"
      [ngClass]="{
        activeItem: item.active,
      }"
      role="option"
      [attr.aria-label]="item.title"
    >
      <span [class]="item.icon" class="p-menuitem-icon"></span>
      <span class="ml-2">{{ item.label }}</span>
      @if (hasSubmenu) {
        <i data-testid="hasSubMenuIcon" class="pi pi-angle-right sub-menu-icon"></i>
      }
    </a>
  </ng-template>
</p-tieredMenu>
