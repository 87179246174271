import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { ComponentsModule } from '../components';
import { DataTableModule } from '../data-table';
import { DirectivesModule } from '../directives';
import { MaterialModule } from '../material/material.module';
import { MetricSearchComponent } from './metric-search.component';
import { MetricSearchValueDefinitionsComponent } from './metric-search-value-definitions/metric-search-value-definitions.component';
import { PipesModule } from '../pipes';
import { SearchModule } from '../search';
import { ShowMoreChipCardsComponent } from './show-more-chip-cards/show-more-chip-cards.component';
import { GetTaxonomyChipItemsPipe } from './pipes/get-taxonomy-chip-items/get-taxonomy-chip-items.pipe';
import { GetCompatibleMetricsItemsPipe } from './pipes/get-compatible-metrics-items/get-compatible-metrics-items.pipe';
import { GetRelatedMetricItemsPipe } from './pipes/get-related-metrics-items/get-related-metric-items.pipe';
import { TablesModule } from '../tables';

@NgModule({
  declarations: [
    MetricSearchComponent,
    MetricSearchValueDefinitionsComponent,
    ShowMoreChipCardsComponent,
    GetTaxonomyChipItemsPipe,
    GetCompatibleMetricsItemsPipe,
    GetRelatedMetricItemsPipe,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ComponentsModule,
    PipesModule,
    DirectivesModule,
    TranslateModule,
    SearchModule,
    DataTableModule,
    TablesModule,
  ],
  exports: [MetricSearchComponent],
})
export class MetricSearchModule {}
