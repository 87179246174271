@if (control) {
  <quill-editor
    data-testid="mention-editor"
    #editor
    [formControl]="control"
    [modules]="modules"
    [placeholder]="placeholder"
    class="quill-mention"
    [ngClass]="size"
  >
  </quill-editor>
  @if (hint) {
    <p class="hint">{{ hint }}</p>
  }
}
