<section class="section section-dynamic" [ngClass]="{ loaded: loaded }">
  <div class="section-header">
    <h4 class="mat-subtitle-2 color-grey-900">{{ title }}</h4>
    <lib-link class="mat-body-1" [text]="linkText" [routerLink]="link"></lib-link>
  </div>
  <div class="section-content" [ngClass]="{ loaded: loaded }">
    <div class="collection collection-dummies">
      @for (dummy of dummies; let index = $index; track index) {
        <lib-skeleton [presentation]="ePresentationV2.bigCard"></lib-skeleton>
      }
    </div>
    <div class="collection collection-items">
      @if (!itemCollection || itemCollection.items.length === 0) {
        <lib-card-empty [presentation]="presentation" [link]="emptyLink" [linkText]="emptyLinkText"></lib-card-empty>
      }
      @for (item of itemCollection?.items; track item.id) {
        <lib-card
          *libFeatureFlag="{ featureFlags: item.featureFlag }"
          [item]="item"
          [presentation]="presentation"
          [path]="link"
          [pathPosition]="linkPosition"
          [limitedCardSize]="true"
        >
        </lib-card>
      }
    </div>
  </div>
</section>
