import { HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import cloneDeep from 'lodash/cloneDeep';
import pickBy from 'lodash/pickBy';
import { BYPASS_INTERCEPTOR_ERROR_MANAGING } from '../../../interceptors';
import { UpsertValueGroup, UpsertValueGroupSet } from '../../../metric-editor-form';
import {
  ApiResponse,
  ApplicationApiDefinition,
  BaseValue,
  BooleanValue,
  ChoiceFieldWidgetType,
  ChoiceTypeDetails,
  DataFormatTemplate,
  GetIndicatorPayload,
  Indicator,
  Metric,
  MetricCategory,
  MinimalIndicator,
  PaginationData,
  PlatformValueGroupSetStatus,
  SearchBarFilterResourceType,
  SearchOptions,
  TextTypeDetails,
  Value,
  ValueDefinitionDisplayType,
  ValueDefinitionSize,
  ValueDefinitionType,
  ValueGroup,
  ValueGroupSet,
  ValueGroupSetStatusTransition,
  ValueGroupSetsTemplate,
  ValueGroupTemplate,
  ValueTemplate,
  ValueType,
} from '../../../models';
import { MetricsIndicatorCategories } from '../../../translations';
import { IndicatorsApiService } from '../../api-services';
import { ApiService } from '../../common/api/api.service';
import { BaseService } from '../../common/base/base.service';
import { TranslateService } from '../../common/translate/translate.service';
import { ClientCoreService } from '../client-core/client-core.service';
import { FeatureFlagService } from '../../../feature-flag';

export interface ListIndicatorsPayload extends ListIndicatorsParams, PaginationData {
  metric_ids?: string[];
  indicator_ids?: string[];
  business_unit_id?: string;
}

export interface ListIndicatorsParams {
  load_related_equivalent?: boolean;
  load_related_core_equivalent?: boolean;
  load_framework?: boolean;
  load_tags?: boolean;
  load_industries?: boolean;
  load_topics?: boolean;
  load_standard_codes?: boolean;
  load_value_group_sets?: boolean;
  load_value_groups?: boolean;
  load_values?: boolean;
  load_taxonomies?: boolean;
  frequencies?: string[];
}

interface SearchIndicatorPayload extends ListIndicatorsParams {
  ai_search?: boolean;
  keywords?: string;
  from?: number;
  size?: number;
  sort?: [{ field?: string; direction?: string }];
  filters?: // {
  //     tags?: string[];
  //     topics?: string[];
  //     active?: boolean | null;
  //     input?: boolean;
  //     industries?: string[];
  //     standard_code_ids?: string[];
  //     min_date?: string;
  //     max_date?: string;
  //     framework_ids?: string[];
  //     metric_ids?: string[];
  //     core_metric_ids?: string[];
  //     categories?: string[];
  //     types?: string[];
  //     topic_categories?: string[];
  //     topic_groups?: string[];
  //     customFilterkey?: string;
  //   }
  any; // TODO: any should be removed, now just here to support the custom filters mapping.
  business_unit_id?: string;
  fiscal_year?: string | number;
  excludes?: {
    framework_ids?: string[];
    categories?: string[];
  };
  not_in_data_request?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ClientIndicatorsService extends IndicatorsApiService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
    private coreService: ClientCoreService,
    private translateService: TranslateService,
    private featureFlagService: FeatureFlagService,
  ) {
    super();
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.indicators;
  }

  payloadFromSearchOptions(searchOptions: SearchOptions): SearchIndicatorPayload {
    const payload: SearchIndicatorPayload = {};
    payload.from = searchOptions.from || 0;
    payload.size = searchOptions.size || this.baseService.defaultPageSize;
    if (searchOptions.query.keywords) {
      payload.keywords = searchOptions.query.keywords;
    }
    if (searchOptions.filters.year) {
      payload.fiscal_year = searchOptions.filters.year.id;
    } else if (searchOptions.item_type === 'metrics_indicator') {
      payload.load_value_group_sets = false;
      payload.load_value_groups = false;
      payload.load_values = false;
    }
    if (searchOptions.filters.source) {
      payload.business_unit_id = searchOptions.filters.source.id;
    }
    payload.filters = {};
    if (searchOptions.filters.topic) {
      if (searchOptions.filters.topic.action === 'category') {
        payload.filters.topic_categories = [searchOptions.filters.topic.id];
      } else if (searchOptions.filters.topic.action === 'group') {
        payload.filters.topic_groups = [searchOptions.filters.topic.id];
      } else {
        payload.filters.topics = [searchOptions.filters.topic.id];
      }
    }

    if (searchOptions.filters.industry) {
      payload.filters.industries = [searchOptions.filters.industry.id];
    }
    if (searchOptions.filters.framework) {
      payload.filters.framework_ids = [searchOptions.filters.framework.id];
    }
    if (searchOptions.filters.questionnaire_framework) {
      payload.filters.framework_ids = [searchOptions.filters.questionnaire_framework.id];
    }
    if (searchOptions.filters.standard_codes) {
      payload.filters.framework_ids = [searchOptions.filters.standard_codes.id];
    }

    const excludedCategories = searchOptions.filter_args?.[SearchBarFilterResourceType.category]?.excluded;
    if (searchOptions.multi_select_filters?.category) {
      payload.filters.categories = searchOptions.multi_select_filters?.category.map((i) => i.id);
    } else if (searchOptions.filters.category) {
      payload.filters.categories = [searchOptions.filters.category.id];
    } else if (excludedCategories) {
      payload.filters.categories = Object.keys(MetricsIndicatorCategories).filter(
        (key) => !excludedCategories.includes(key as MetricCategory),
      );
    }
    if (searchOptions.filters.tag) {
      payload.filters.tags = [searchOptions.filters.tag.id];
    }

    if (searchOptions.filters.metric_groups) {
      payload.filters.metric_group_ids = [searchOptions.filters.metric_groups.id];
    }

    if (searchOptions.filters.industries) {
      payload.filters.industries = [searchOptions.filters.industries.id];
    }

    if (searchOptions.filters.taxonomy) {
      if (searchOptions.filters.taxonomy.id == 'outside_taxonomy') {
        payload.filters.outside_taxonomy = true;
      } else {
        payload.filters.taxonomy_ids = [searchOptions.filters.taxonomy.id];
      }
    }

    payload.filters.active = null;
    if (this.featureFlagService.areAnyFeatureFlagsEnabled(['metric_deactivation_enabled'])) {
      payload.filters.active = true;
      if (searchOptions.filters.active) {
        payload.filters.active =
          searchOptions.filters.active?.id === 'null' ? null : searchOptions.filters.active.id === 'true';
      }
    }

    if (searchOptions.sort) {
      switch (searchOptions.sort.id) {
        case 'updated':
          payload.sort = [
            {
              field: 'start',
              direction: 'desc',
            },
          ];
          break;
        case 'name':
          payload.sort = [
            {
              field: 'description',
              direction: 'asc',
            },
          ];
          break;
        case 'score':
          break;
        default:
          payload.sort = [
            {
              field: searchOptions.sort.id,
              direction: searchOptions.sort.id === 'description' ? 'asc' : 'desc',
            },
          ];
      }
    }

    if (searchOptions.custom_filters) {
      for (const filterKey of Object.keys(searchOptions.custom_filters)) {
        payload.filters[filterKey] = searchOptions.custom_filters[filterKey];
      }
    }

    if (searchOptions.excludes) {
      payload.excludes = searchOptions.excludes;
    }

    payload.load_value_group_sets = false;
    payload.load_value_groups = false;
    payload.load_values = false;
    payload.ai_search = Boolean(searchOptions.custom_properties?.ai_search);
    payload.load_taxonomies = Boolean(searchOptions.custom_properties?.load_taxonomies);
    payload.not_in_data_request = searchOptions.not_in_data_request;
    payload.fiscal_year = searchOptions.fiscal_year;

    return payload;
  }

  searchMetrics(searchOptions: SearchOptions): Observable<ApiResponse<Metric[]>> {
    const payload: SearchIndicatorPayload = this.payloadFromSearchOptions(searchOptions);
    return this.apiService.post(`${this.servicePath}${this.resource}/search`, payload).pipe(
      map((result: ApiResponse<Indicator[]>) => {
        result.data.map((indicator) => {
          indicator.indicator_id = indicator.id;
          indicator.id = String(indicator.metric_id);
          return indicator;
        });
        return result;
      }),
    );
  }

  getDatasetValues(year: number, metric_ids: string[]): Observable<ApiResponse<Indicator[]>> {
    const payload = {
      size: 100,
      load_related_equivalent: false,
      load_related_core_equivalent: false,
      fiscal_year: year,
      filters: {
        metric_ids,
      },
    };
    return this.apiService.post(`${this.servicePath}${this.resource}/search`, payload);
  }

  search(searchOptions: SearchOptions): Observable<ApiResponse<Indicator[]>> {
    const payload: SearchIndicatorPayload = this.payloadFromSearchOptions(searchOptions);

    return this.apiService.post(`${this.servicePath}${this.resource}/search`, payload);
  }

  getIndicator(id: string, payload?: GetIndicatorPayload): Observable<ApiResponse<Indicator>> {
    let params = new HttpParams();
    if (payload) {
      Object.entries(payload).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item: string) => {
            params = params.append(key, item);
          });
        } else if (value != undefined && value != null) {
          params = params.append(key, value as string);
        }
      });
    }
    return this.apiService.get(`${this.servicePath}${this.resource}/indicators/${id}`, { params });
  }

  getIndicatorsByCoreMetricIds(core_metric_ids: string[], fiscal_year?: number): Observable<ApiResponse<Indicator[]>> {
    const payload: SearchIndicatorPayload = {
      filters: {
        core_metric_ids,
      },
      fiscal_year,
      size: core_metric_ids.length ? core_metric_ids.length : 10,
    };
    return this.apiService.post(`${this.servicePath}${this.resource}/search`, payload);
  }

  getIndicatorByMetricIds(
    metric_ids: string[],
    fiscal_year?: string,
    business_unit_id?: string,
    summary: boolean = false,
  ): Observable<ApiResponse<Indicator[]>> {
    const payload: SearchIndicatorPayload = {
      filters: {
        metric_ids,
        active: null,
      },
      size: metric_ids.length,
      fiscal_year,
      business_unit_id,
    };
    if (summary) {
      payload.load_value_group_sets = false;
      payload.load_value_groups = false;
      payload.load_values = false;
    }
    return this.apiService.post(`${this.servicePath}${this.resource}/search`, payload);
  }

  deleteValueGroup(
    indicatorId: string,
    valueGroupSetId: string,
    valueGroupId: string,
  ): Observable<ApiResponse<Indicator>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/indicators/${indicatorId}/valuegroupsets/${valueGroupSetId}/valuegroups/${valueGroupId}`,
    );
  }

  deleteValue(
    indicator_id: string,
    value_group_set_id: string,
    value_group_id: string,
    value_id: string,
    reportId?: string,
  ): Observable<ApiResponse<Indicator>> {
    let params = new HttpParams();

    if (reportId) {
      params = params.append('report_id', reportId);
    }

    return this.apiService.delete(
      `${this.servicePath}${this.resource}/indicators/${indicator_id}/valuegroupsets/${value_group_set_id}/valuegroups/${value_group_id}/values/${value_id}`,
      {},
      params,
    );
  }

  deleteValueGroupSet(indicator_id: string, value_group_set_id: string): Observable<null> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/indicators/${indicator_id}/valuegroupsets/${value_group_set_id}`,
    );
  }

  updateMetric(indicatorId: string, payload: UpsertValueGroupSet): Observable<ApiResponse<Indicator>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/indicators/${indicatorId}/values`,
      payload,
      undefined,
      undefined,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  listIndicators(payload: ListIndicatorsPayload, params?: ListIndicatorsParams): Observable<ApiResponse<Indicator[]>> {
    const httpParams = this.toParams(params);

    return this.apiService.post(
      `${this.servicePath}${this.resource}/indicators/list`,
      pickBy(payload, (v: unknown) => v != null),
      httpParams,
    );
  }

  listMinimalIndicators(
    frameworkId?: string,
    payload?: {
      categories?: MetricCategory[];
      metric_ids?: string[];
      indicator_ids?: string[];
    },
  ): Observable<ApiResponse<MinimalIndicator[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/minimal/indicators`, payload);
  }

  moveValueGroup(
    indicator_id: string,
    value_group_set_id: string,
    value_group_id: string,
    position: number,
  ): Observable<ApiResponse<Indicator>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/indicators/${indicator_id}/value_group_sets/${value_group_set_id}/value_groups/${value_group_id}/move/${position}`,
      {},
    );
  }

  updateVGSetStatus(
    indicator_id: string,
    value_group_set_id: string,
    business_unit_id: string,
    frequency_code: string,
    status: PlatformValueGroupSetStatus,
    report_id?: string,
  ): Observable<ApiResponse<Indicator>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/indicators/${indicator_id}/value_group_sets/status/${status}`,
      { value_group_set_id, business_unit_id, frequency_code, report_id },
    );
  }

  // utilities
  getValueString(value: Value, valueString: string): string {
    if (value.value) {
      if (!(value.type === 'tip' || value.type === 'subtitle' || value.type === 'label')) {
        if (value.type === 'boolean' && (value.value as BooleanValue | null)) {
          valueString =
            valueString +
            (value.label ? value.label + ' - ' : '') +
            this.translateService.instant((value.value as BooleanValue).value === true ? 'Yes' : 'No') +
            '<br>';
        } else {
          const valueLabel = value.label ? `${value.label} - ` : '';
          valueString = `${valueString}${valueLabel}${String(value.value || '')}<br>`;
        }
      }
    }
    return valueString;
  }

  getMetricDisplayData<T extends BaseValue>(business_unit_id: string, indicator: Indicator): ValueGroupSetsTemplate<T> {
    if (indicator.value_group_sets) {
      let indicatorValueGrpSet = indicator.value_group_sets.find((x) => x.business_unit_id === business_unit_id);
      if (!indicatorValueGrpSet) {
        indicatorValueGrpSet = indicator.value_group_sets.find((x) => !x.consolidated);
      }
      if (!business_unit_id) {
        indicatorValueGrpSet = indicator.value_group_sets.find((x) => x.consolidated) ?? indicator.value_group_sets[0];
      }
      if (indicatorValueGrpSet) {
        return this.getDataFields<T>(indicatorValueGrpSet.value_groups ?? [], indicatorValueGrpSet);
      }
      throw new Error('No value group set exists for the source and fiscal year');
    } else {
      throw new Error('No value group sets exists');
    }
  }

  public createOrUpdateGroup(
    valueGroupSet: ValueGroupSetsTemplate<BaseValue>,
    business_unit_id: string,
    frequency_code: string,
  ): UpsertValueGroupSet {
    const valueGroups: UpsertValueGroup[] = [];
    valueGroupSet.group.forEach((group) => {
      const tempValues: {
        id?: string;
        value: string | null;
        value_definition_id: string | undefined;
        unit?: string;
      }[] = [];
      group.value.forEach((value) => {
        if (!value.hasError && value.isEdited) {
          if (
            !(
              value.dataFormats.dataFormatType === ValueDefinitionDisplayType.tip ||
              value.dataFormats.dataFormatType === ValueDefinitionDisplayType.subtitle
            )
          ) {
            const val = {
              // TODO: evaluate this, syncfusion richtext editor raises an error toString of null
              // value: value.value || value.value.toString() === '0'
              //   ? value.value
              //   : null,
              // See if the following works
              value: String(value.value ?? ''),
              value_definition_id: value.value_definition_id,
              ...(value.id && valueGroupSet.id && { id: value.id }),
            };
            if (
              (value.dataFormats.dataFormatType === ValueDefinitionDisplayType.boolean &&
                (value.value as BooleanValue | undefined)?.value !== undefined) ||
              value.dataFormats.dataFormatType !== ValueDefinitionDisplayType.boolean
            ) {
              tempValues.push(val);
            }
          }
        }
      });
      const grp = {
        id: group.id,
        value_definition_group_id: group.value_definition_group_id,
        values: tempValues,
      } as UpsertValueGroup;
      valueGroups.push(grp);
    });
    return {
      id: valueGroupSet.id,
      frequency_code,
      business_unit_id,
      value_groups: valueGroups,
    };
  }

  // helper methods

  getDataFields<T extends BaseValue>(
    valueGroups: ValueGroup[],
    valueGroupSet: ValueGroupSet,
  ): ValueGroupSetsTemplate<T> {
    let dataFormatsId = 1;
    const tempValueGroups: ValueGroupTemplate<T>[] = valueGroups
      .map((valueGroup) => {
        const tempValue: ValueTemplate<T>[] = [];
        valueGroup.values?.forEach((value) => {
          const tempValueData = { ...value } as T;
          const tempDataFormat = this.getDataFormatTemplateFromValue<T>(tempValueData, dataFormatsId);
          dataFormatsId++;
          if (!(valueGroup.repeatable && value.type === ValueDefinitionType.calculated)) {
            tempValue.push({
              id: value.id ? value.id : undefined,
              value_definition_id: value.value_definition_id,
              position: value.position,
              dataFormats: tempDataFormat,
              size: tempDataFormat.data.size,
              newline: value.newline,
              required: value.required,
              value: (tempValueData.value as ValueType) ?? '',
              isEdited: false,
              conditional_triggers: value.conditional_triggers,
            });
          }
        });
        tempValue.sort((a, b) => (a.position && b.position ? a.position - b.position : 0));
        return {
          id: valueGroup.id,
          value_definition_group_id: valueGroup.value_definition_group_id,
          repeatable: valueGroup.repeatable,
          position: valueGroup.position,
          subposition: valueGroup.subposition,
          value: tempValue,
          showOptions: valueGroup.repeatable,
          group_max_repetition: valueGroup.group_max_repetition,
          indent: valueGroup.indent,
          conditional_triggers: valueGroup.conditional_triggers,
        } as ValueGroupTemplate<T>;
      })
      .sort((a, b) => (a.position && b.position ? a.position - b.position : 0));
    return {
      id: valueGroupSet.id,
      group: tempValueGroups,
      explanation: valueGroupSet.explanation ?? '',
      lastValueId: dataFormatsId,
    };
  }

  public deleteGroup<T extends BaseValue>(
    group: ValueGroupTemplate<T>,
    set: ValueGroupSetsTemplate<T>,
  ): ValueGroupSetsTemplate<T> {
    if (set.group.indexOf(group) === set.group.length - 1) {
      const prevGroup = set.group[set.group.indexOf(group) - 1];
      prevGroup.repeatable = true;
    }
    set.group.splice(set.group.indexOf(group), 1);
    if (set.group.length === 1) {
      set.group[0].showOptions = false;
    }
    return set;
  }

  repeatGroup<T extends BaseValue>(
    group: ValueGroupTemplate<T>,
    valueGroupSet: ValueGroupSetsTemplate<T>,
  ): ValueGroupSetsTemplate<T> {
    group.repeatable = false;
    group.showOptions = true;
    const tempValue: ValueTemplate<T>[] = [];
    group.value.forEach((item) => {
      const formats: DataFormatTemplate<T> = cloneDeep(item.dataFormats);
      formats.id = (valueGroupSet.lastValueId++).toString();
      formats.data.value = '';
      tempValue.push({
        value_definition_id: item.value_definition_id,
        position: item.position,
        dataFormats: formats,
        size: item.size,
        newline: item.newline,
        value: '',
      });
    });

    if (valueGroupSet.group.indexOf(group) < valueGroupSet.group.length - 1) {
      valueGroupSet.group.forEach((grp) => {
        if (valueGroupSet.group.indexOf(grp) > valueGroupSet.group.indexOf(group)) {
          grp.position = grp.position + 2;
        }
      });
    }
    valueGroupSet.group.push({
      showOptions: true,
      showAddButton: true,
      value_definition_group_id: group.value_definition_group_id,
      id: undefined,
      position: group.position + 1,
      repeatable: true,
      value: tempValue,
      group_max_repetition: group.group_max_repetition,
    });
    valueGroupSet.group.sort((a, b) => (a.position && b.position ? a.position - b.position : 0));
    return valueGroupSet;
  }

  public listValueProgressSummary(
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    frequencyCode: string,
    consolidatedBusinessUnitId: string,
    fiscalYearPeriodId?: string,
    offset?: number,
    limit?: number,
  ): Observable<ApiResponse<ValueGroupSet[]>> {
    const params: Record<string, unknown> = {
      ['consolidated_business_unit_id']: consolidatedBusinessUnitId,
      ['frequency_code']: frequencyCode,
      ['fiscal_year_period_id']: fiscalYearPeriodId,
      ['offset']: offset,
      ['limit']: limit,
    };

    return this.apiService.get(
      `${this.servicePath}${this.resource}/value_definition_group/${valueDefinitionGroupId}/value_definition/${valueDefinitionId}/value_progress_summary`,
      { params: pickBy(params, (param: unknown) => param != null) },
    );
  }

  public listValueProgressSummaryGroup(
    valueDefinitionGroupId: string,
    frequencyCode: string,
    consolidatedBusinessUnitId: string,
    offset?: number,
    limit?: number,
  ): Observable<ApiResponse<ValueGroupSet[]>> {
    const params: Record<string, unknown> = {
      ['consolidated_business_unit_id']: consolidatedBusinessUnitId,
      ['frequency_code']: frequencyCode,
      ['offset']: offset,
      ['limit']: limit,
    };

    return this.apiService.get(
      `${this.servicePath}${this.resource}/value_definition_group/${valueDefinitionGroupId}/value_progress_summary`,
      { params: pickBy(params, (param: unknown) => param != null) },
    );
  }

  checkPayloadValidity(valueGroupSet: ValueGroupSetsTemplate<BaseValue>): string {
    let editedItemsCount = 0;
    let errorCount = 0;
    valueGroupSet.group.forEach((group) => {
      group.value.forEach((value) => {
        if (value.isEdited) {
          editedItemsCount++;
        }
        if (value.isEdited && value.hasError) {
          errorCount++;
        }
      });
    });
    return !errorCount ? 'success' : editedItemsCount > errorCount ? 'partial' : 'error';
  }

  public getValueGroupSetStatusTransitions(
    indicatorId: string,
    valueGroupSetId: string,
  ): Observable<ApiResponse<ValueGroupSetStatusTransition[]>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/indicators/${indicatorId}/value_group_set/${valueGroupSetId}/status_transitions`,
    );
  }

  private getDataFormatTemplateFromValue<T extends BaseValue>(value: T, index: number): DataFormatTemplate<T> {
    const tempValueData = { ...value };
    switch (value.type) {
      case ValueDefinitionType.text: {
        if ((value.type_details as TextTypeDetails).rich_text) {
          tempValueData.size = ValueDefinitionSize.large;
          return {
            id: `text${index}`,
            dataFormatType: ValueDefinitionDisplayType.text_rich,
            data: tempValueData,
          };
        } else if ((value.type_details as TextTypeDetails).textarea) {
          const data = value.validators?.find(
            (value) => value.validator_type === 'max_length' || value.validator_type === 'min_length',
          );
          if (data) {
            return {
              id: index.toString(),
              dataFormatType: ValueDefinitionDisplayType.text_area_validation,
              data: tempValueData,
            };
          } else {
            return {
              id: index.toString(),
              dataFormatType: ValueDefinitionDisplayType.text_area,
              data: tempValueData,
            };
          }
        } else {
          return {
            id: index.toString(),
            dataFormatType: ValueDefinitionDisplayType.text_simple,
            data: tempValueData,
          };
        }
      }
      case ValueDefinitionType.tip: {
        tempValueData.size = ValueDefinitionSize.large;
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.tip,
          data: tempValueData,
        };
      }
      case ValueDefinitionType.subtitle:
      case ValueDefinitionType.label: {
        tempValueData.size = ValueDefinitionSize.large;
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.subtitle,
          data: tempValueData,
        };
      }
      case ValueDefinitionType.number: {
        tempValueData.unit = this.coreService.getValueUnit(tempValueData, []);
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.number,
          data: tempValueData,
        };
      }
      case ValueDefinitionType.date: {
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.date,
          data: tempValueData,
        };
      }
      case ValueDefinitionType.datetime: {
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.datetime,
          data: tempValueData,
        };
      }
      case ValueDefinitionType.choice: {
        let format = ValueDefinitionDisplayType.choice;
        if ((tempValueData.type_details as ChoiceTypeDetails).widget_type === ChoiceFieldWidgetType.multi_chip) {
          tempValueData.size = ValueDefinitionSize.large;
          format = ValueDefinitionDisplayType.choice_searchable;
        } else if ((tempValueData.type_details as ChoiceTypeDetails).widget_type === ChoiceFieldWidgetType.checkboxes) {
          format = ValueDefinitionDisplayType.choice_multiple;
        }
        // TODO: Change it to ValueDefinitionType.choice_radio once ready to do data migration.
        else {
          format = ValueDefinitionDisplayType.choice;
        }
        return { id: index.toString(), dataFormatType: format, data: tempValueData };
      }
      case ValueDefinitionType.boolean: {
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.boolean,
          data: tempValueData,
        };
      }
      case ValueDefinitionType.file_v2: {
        tempValueData.size = ValueDefinitionSize.large;
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.file_v2,
          data: tempValueData,
        };
      }
      case ValueDefinitionType.calculated: {
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.calculated,
          data: tempValueData,
        };
      }
      case ValueDefinitionType.document: {
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.document,
          data: tempValueData,
        };
      }
      case ValueDefinitionType.metric: {
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.metric,
          data: tempValueData,
        };
      }
    }
  }

  private toParams<T>(data?: T): HttpParams {
    let httpParams = new HttpParams();

    if (data) {
      Object.entries(data).forEach(([paramKey, paramValue]) => {
        if (paramValue instanceof Array) {
          paramValue.forEach((value: string | number | boolean) => {
            httpParams = httpParams.append(paramKey, value);
          });
        } else {
          httpParams = httpParams.append(paramKey, paramValue as string | number | boolean);
        }
      });
    }

    return httpParams;
  }
}
