@if (data$ | async; as data) {
  <div class="content p-4">
    <h3>{{ "Resource List" | translate }}</h3>
    @if (!data.isLoading) {
      <lib-searchable-select-input
        [options]="data.resourceLists"
        [control]="form.controls.resourceList"
        [reachedEnd]="true"
        [bindOptionLabelFn]="bindOptionLabelFn"
        [compareFn]="compareFn"
      ></lib-searchable-select-input>
      <lib-multi-select-dropdown
        [control]="form.controls.resourceListItems"
        [options]="data.resourceListItems | objectValues"
        [withSelectAllOption]="true"
      >
      </lib-multi-select-dropdown>
      <button
        lib-button
        [buttonType]="'primary'"
        class="mt-3"
        [disabled]="!form.controls.resourceListItems.value.length"
        (click)="bulkAdd(data.resourceListItems)"
      >
        {{ "Add" | translate }}
      </button>
      @if (optionListItemsCount) {
        <lib-info-banner
          class="mt-2"
          [message]="'Previously added options won\'t be duplicated. Each option must be unique.' | translate"
          [type]="'info'"
        ></lib-info-banner>
      }
    } @else {
      <lib-skeleton-list [numberOfRows]="9"></lib-skeleton-list>
    }
  </div>
}
