import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, OperatorFunction, pipe, throwError, UnaryFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UpsertValue, UpsertValueGroup, UpsertValueGroupSet } from '../../models/upsertValue';
import {
  MetricEditorErrorDialogComponent,
  MetricEditorErrorDialogConfig,
} from '../../components/metric-editor-error-dialog/metric-editor-error-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '../../../services/common';
import { DialogsService } from '../../../dialogs';
import { Value, ValueGroupSet } from '../../../models';
import { HttpErrorResponse } from '@angular/common/http';
import { DUPLICATE_REPEATABLE_GROUP_CONTEXT_ERROR } from '../../../constants';

@Injectable({
  providedIn: 'root',
})
export class MetricEditorErrorStateService {
  private _valueGroupSet$: BehaviorSubject<ValueGroupSet | undefined> = new BehaviorSubject<ValueGroupSet | undefined>(
    undefined,
  );

  public valueGroupSet$: Observable<ValueGroupSet | undefined>;

  public currentErrorDialog?: MatDialogRef<unknown>;

  constructor(
    private readonly translateService: TranslateService,
    private readonly dialogsService: DialogsService,
  ) {
    this.valueGroupSet$ = this._valueGroupSet$.asObservable();
  }

  public updateValueGroupSet(valueGroupSet: ValueGroupSet | undefined): void {
    this._valueGroupSet$.next(valueGroupSet);
  }

  public manageUpsertIndicatorValueError<T>(
    vgs: UpsertValueGroupSet | undefined,
  ): UnaryFunction<Observable<T | null | undefined>, Observable<T>> {
    return pipe(
      catchError((error: unknown) => {
        this.showErrorMessage(vgs, error);
        return throwError(() => error);
      }) as OperatorFunction<T | null | undefined, T>,
    );
  }

  private showErrorMessage(upsertValueGroupSet: UpsertValueGroupSet | undefined, error?: unknown): void {
    if (!this.currentErrorDialog && upsertValueGroupSet) {
      const upsertValueGroup: UpsertValueGroup | undefined = upsertValueGroupSet.value_groups[0];
      const upsertValue: UpsertValue | undefined = upsertValueGroup.values[0];

      let errorMessage: string | undefined;
      const value = this.extractValueFromValueGroupSet(
        upsertValueGroup.value_definition_group_id,
        upsertValue?.value_definition_id,
      );
      const valueLabel = value?.label ?? this.translateService.instant('your last modified field');

      if (
        error instanceof HttpErrorResponse &&
        error?.status === 422 &&
        error.error.errors[0].type === DUPLICATE_REPEATABLE_GROUP_CONTEXT_ERROR
      ) {
        errorMessage = this.translateService.instant(
          'Repeatable group context repetition must be unique. Your latest changes to {valueLabel} have not been saved.',
          { valueLabel },
        );
      }

      this.currentErrorDialog = this.dialogsService.open<
        MetricEditorErrorDialogComponent,
        MetricEditorErrorDialogConfig
      >(MetricEditorErrorDialogComponent, {
        data: { errorMessage, valueLabel },
      });

      this.currentErrorDialog.afterClosed().subscribe(() => {
        this.currentErrorDialog = undefined;
      });
    }
  }

  private extractValueFromValueGroupSet(
    valueDefinitionGroupId?: string,
    valueDefinitionId?: string,
  ): Value | undefined {
    const valueGroupSet = this._valueGroupSet$.value;

    if (!valueGroupSet || !valueDefinitionGroupId || !valueDefinitionId) {
      return undefined;
    }

    for (const group of valueGroupSet.value_groups ?? []) {
      if (group.value_definition_group_id === valueDefinitionGroupId) {
        for (const value of group.values ?? []) {
          if (value.value_definition_id === valueDefinitionId) {
            return value;
          }
        }
      }
    }

    return undefined;
  }
}
