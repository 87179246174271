@if (metricSearchStateService.metricValueDefinitions$ | async; as metricValueDefinitions) {
  @if (metricValueDefinitions[metricId]; as vdContainer) {
    <div class="metric-search-vd-container">
      @if (vdContainer.repeatable.length) {
        <div class="ml-7">
          <div class="metric-search-vd-row">
            <mat-icon class="ml-4" [svgIcon]="'info'"> </mat-icon>
            <span class="ml-2 repeatable-info">{{ "These fields are part of a repeatable group." | translate }}</span>
          </div>
          <ng-container *ngTemplateOutlet="valueDefinitions; context: { valueDefinitions: vdContainer.repeatable }">
          </ng-container>
        </div>
      }
      <ng-container *ngTemplateOutlet="valueDefinitions; context: { valueDefinitions: vdContainer.nonRepeatable }">
      </ng-container>
    </div>
  } @else {
    <div class="dummy-container">
      <lib-skeleton-list data-testid="dummy-matTable" [numberOfRows]="6"></lib-skeleton-list>
    </div>
  }
}

<ng-template #valueDefinitions let-valueDefinitions="valueDefinitions">
  @if (metricSearchStateService.selections$ | async; as selections) {
    @for (vd of valueDefinitions; track vd) {
      <ng-container *ngTemplateOutlet="row; context: { label: vd.label, state: selections, vd: vd }"> </ng-container>
    }
  }
</ng-template>

<ng-template #row let-label="label" let-state="state" let-vd="vd">
  <div class="metric-search-vd-row">
    <mat-checkbox
      [attr.data-testid]="'select-field-checkbox-' + vd.key"
      class="ml-2"
      (change)="onCheckChanged(vd.key)"
      [checked]="state[vd.key]"
      [disabled]="vd.disabled"
      [indeterminate]="!state[vd.key] && (state | objectEntries | some: indeterminateSelection : vd.key)"
    >
    </mat-checkbox>
    @if (vd.children?.length) {
      <button
        lib-button
        class="ml-2"
        (click)="toggleValueDefinition(vd.key)"
        [isLarge]="true"
        [attr.data-testid]="'expand-fields-button-' + vd.key"
      >
        <mat-icon [svgIcon]="toggledValueDefinitions[vd.key] ? 'arrow-head-down' : 'arrow-head-right'"></mat-icon>
      </button>
    }
    <span class="ml-2 field-label">{{ label }}</span>
  </div>
  @if (toggledValueDefinitions[vd.key] && vd.children?.length) {
    <div class="ml-7">
      <ng-container *ngTemplateOutlet="valueDefinitions; context: { valueDefinitions: vd.children }"></ng-container>
    </div>
  }
</ng-template>
