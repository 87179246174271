<p class="ytd-label">{{ indicatorName }}</p>

@if (valueGroupFormGroup.valueGroupRef | formatFieldUniqueId: control.valueRef; as focusId) {
  <div class="ytd-field">
    <div
      [class]="'field-focus large'"
      [libIsActiveField]="focusedField$ | async"
      [focusFieldUniqueId]="focusFieldUniqueId$ | async"
      [value]="control.valueRef"
      [focusId]="focusId"
      [ngClass]="{
        focused: isFocusable && isFocusEnabled,
      }"
      (mousedown)="selectFieldForFocus(focusId, control.valueRef, valueGroup)"
      data-testid="field-focus"
    >
      <lib-numeric-input
        [readonly]="true"
        [labelPosition]="'left'"
        [label]="'YTD ' + yearToDateCalculation"
        [control]="control"
        [sameSizeLabels]="true"
        [isCalculated]="true"
        [suffix]="unit"
        [forceAllowDecimals]="forceAllowDecimals"
        [maxDecimals]="maxDecimals"
      ></lib-numeric-input>
      @if (getError(control, "ytdBelowMin")?.min) {
        <mat-error>
          {{
            "The YTD value is below the minimum allowed: {min_val}."
              | translate: { min_val: getError(control, "ytdBelowMin")?.min }
          }}
        </mat-error>
      }

      @if (getError(control, "ytdExceeded")?.max) {
        <mat-error>
          {{
            "The YTD value exceeds the maximum allowed: {max_val}."
              | translate: { max_val: getError(control, "ytdExceeded")?.max }
          }}
        </mat-error>
      }
    </div>
  </div>
}

<div
  class="periods-fields"
  [ngClass]="{
    'periods-fields-quarterly': frequency === 'quarterly',
    'periods-fields-monthly': frequency === 'monthly',
  }"
>
  @for (freqControl of frequenciesControl; track frequencyValueFormControlTrackBy($index, freqControl)) {
    @if (valueGroupFormGroup.valueGroupRef | formatFieldUniqueId: freqControl.valueRef; as focusId) {
      <div
        [class]="'field-focus large'"
        [libIsActiveField]="focusedField$ | async"
        [focusFieldUniqueId]="focusFieldUniqueId$ | async"
        [value]="freqControl.valueRef"
        [focusId]="focusId"
        [ngClass]="{
          focused: isFocusable && isFocusEnabled,
        }"
        (mousedown)="selectFieldForFocus(focusId, freqControl.valueRef, valueGroup)"
        data-testid="field-focus"
      >
        <lib-numeric-input
          [readonly]="!!freqControl.valueRef.is_read_only"
          [labelPosition]="'left'"
          [label]="freqControl.valueRef.fiscal_year_period! | frequencyFieldLabel: frequency"
          [matTooltip]="freqControl.valueRef.fiscal_year_period! | frequencyFieldPeriod"
          [control]="freqControl"
          [sameSizeLabels]="true"
          [isCalculated]="false"
          [suffix]="unit"
          [maxDecimals]="maxDecimals"
          [forceAllowDecimals]="forceAllowDecimals"
          [fiscalYearPeriod]="freqControl.valueRef.fiscal_year_period"
        ></lib-numeric-input>
        @if (getError(freqControl, "ytdBelowMin")?.min) {
          <mat-error>
            The YTD value is below the minimum allowed: {{ getError(freqControl, "ytdBelowMin")?.min }}.
          </mat-error>
        }

        @if (getError(freqControl, "ytdExceeded")?.max) {
          <mat-error>
            The YTD value exceeds the maximum allowed: {{ getError(freqControl, "ytdExceeded")?.max }}.
          </mat-error>
        }
        @if (
          freqControl.valueRef.unresolved_regular_comments_and_replies ||
          !!freqControl.valueRef.unresolved_rejection_comments_and_replies
        ) {
          <div
            lib-field-comment-counter
            class="comments-count"
            [unresolved_regular_comments_and_replies]="freqControl.valueRef.unresolved_regular_comments_and_replies"
            [unresolved_rejection_comments_and_replies]="freqControl.valueRef.unresolved_rejection_comments_and_replies"
            (actionSelected)="selectFieldForFocus(focusId, freqControl.valueRef, valueGroup, $event)"
          ></div>
        }
        @if (displayFieldActions) {
          <div
            lib-field-actions
            data-testid="fieldActions"
            [valueDefinitionType]="freqControl.valueRef.type"
            [unresolvedRejectionCommentsAndReplies]="freqControl.valueRef.unresolved_rejection_comments_and_replies"
            [valueGroupSetStatus]="valueGroupSetStatus"
            [dataRequestSourceStatus]="dataRequestSourceStatus"
            [collaboratorResponsibility]="collaboratorResponsibility"
            (actionSelected)="selectFieldForFocus(focusId, freqControl.valueRef, valueGroup, $event)"
          ></div>
        }
      </div>
    }
  }
</div>
