import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControlStatus, ValidationErrors } from '@angular/forms';
import { finalize, Subject, take, takeUntil, tap } from 'rxjs';
import { FileDocumentInterface, FileTypeDetailsV2, FileValue, PresentationV2 } from '../../../models';
import { ValidationMessageService } from '../../../services/common';
import { DEFAULT_DOCUMENT_CONTEXT, DocumentContext } from '../../models/documentContext';
import { ValueFormControl } from '../../models/valueFormControl';
import { BaseMetricEditorFormStateService } from '../../services/base-metric-editor-form-state/base-metric-editor-form-state.service';
import { FileDeleteEventData } from './metric-editor-file-card/metric-editor-file-card.component';

const DEFAULT_MAX_FILES_LIMIT = 1;

@Component({
  selector: 'lib-metric-editor-file-attachment-v2',
  templateUrl: './metric-editor-file-attachment-v2.component.html',
  styleUrls: ['./metric-editor-file-attachment-v2.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0, display: 'none' }),
        animate('200ms ease-in', style({ display: 'flex', opacity: 1 })),
      ]),
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        style({ display: 'flex', opacity: 1 }),
        animate('200ms ease-out', style({ display: 'none', opacity: 0 })),
      ]),
    ]),
  ],
})
export class MetricEditorFileAttachmentV2Component implements OnInit, OnDestroy {
  @Input({ required: true }) valueFormControl!: ValueFormControl<FileTypeDetailsV2>;
  @Input() documentContext: DocumentContext = DEFAULT_DOCUMENT_CONTEXT;
  @Input() messages?: ValidationErrors;

  fileDocumentsList: FileDocumentInterface[] = [];
  fileValues: FileValue[] = [];
  maxFilesLimit: number = DEFAULT_MAX_FILES_LIMIT;
  filesLoaded: boolean = true;
  readonly ePresentationV2: typeof PresentationV2 = PresentationV2;
  maxFilesLimitReached: boolean = false;

  attachmentOptional: boolean = false;
  attachmentEnabled: boolean = false;
  addingNewFile: boolean = false;

  private destroy$ = new Subject<void>();
  private valueUpdateSubject$ = new Subject<FileValue[] | null>();

  constructor(
    private validationMessageService: ValidationMessageService,
    private baseMetricEditorFormStateService: BaseMetricEditorFormStateService,
  ) {}

  ngOnInit(): void {
    this.attachmentOptional = this.valueFormControl.valueRef.type_details.attachment_optional;
    this.attachmentEnabled = this.valueFormControl.valueRef.type_details.attachment_enabled;
    this.maxFilesLimit = this.valueFormControl.valueRef.type_details.max_files || DEFAULT_MAX_FILES_LIMIT;

    this.updateMaxFilesLimitReached();
    this.updateFileValues();
    this.messages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };
    this.updateFileDocumentsList();

    this.valueFormControl.registerOnChange(() => {
      this.valueUpdateSubject$.next(this.valueFormControl.value as FileValue[]);
    });

    this.valueUpdateSubject$
      .pipe(
        tap(() => {
          this.addingNewFile = false;
          this.updateFileValues();
          this.updateMaxFilesLimitReached();

          if (!this.isValueInFileDocumentList()) {
            this.updateFileDocumentsList();
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private isValueInFileDocumentList(): boolean {
    if (!this.valueFormControl.value) {
      return false;
    }

    return this.valueFormControl.value.every((value: FileValue) =>
      Boolean(this.fileDocumentsList.find((fileDocument: FileDocumentInterface) => fileDocument.id === value.file_id)),
    );
  }

  private updateFileDocumentsList(): void {
    const documentIds: string[] = (this.valueFormControl.value as FileValue[] | [])
      ?.map((fileValue) => fileValue.file_id)
      .filter((fileId): fileId is string => Boolean(fileId));
    if (documentIds) {
      this.filesLoaded = false;
      this.baseMetricEditorFormStateService
        .getDocumentMetadata(documentIds)
        .pipe(
          take(1),
          finalize(() => {
            this.filesLoaded = true;
          }),
        )
        .subscribe((fileDocs) => {
          this.fileDocumentsList = fileDocs;
        });
    }
  }

  public deleteFileValue(event: FileDeleteEventData): void {
    if (event.isNew) {
      this.addingNewFile = false;
      this.fileValues = this.fileValues.filter((fileVal, index) => index !== event.index);
      return;
    }

    if (event.index === -1) {
      this.updateFileValues();
      this.valueFormControl.enable();
    } else {
      this.fileValues = this.fileValues.filter((fileVal, index) => index !== event.index);
      const valuesToUpdate = (this.valueFormControl.value as FileValue[]).filter(
        (fileVal, index) => index !== event.index,
      );
      if (valuesToUpdate.length) {
        this.valueFormControl.setValue(valuesToUpdate);
      } else {
        this.valueFormControl.resetValue({ shouldPrompt: false });
      }
    }
  }

  private updateFileValues(): void {
    this.fileValues = (
      this.valueFormControl.value
        ? Array.isArray(this.valueFormControl.value)
          ? [...this.valueFormControl.value]
          : [this.valueFormControl.value]
        : []
    ) as FileValue[];

    if (this.attachmentOptional && !this.attachmentEnabled && !this.fileValues.length) {
      this.addFileURLValue();
    }
  }

  addFileDoc(fileDoc: FileDocumentInterface): void {
    this.fileDocumentsList.push(fileDoc);
  }

  addFileValue(fileValue: FileValue): void {
    this.addingNewFile = true;
    this.fileValues.push(fileValue);
  }

  addFileURLValue(): void {
    this.addingNewFile = true;
    this.fileValues.push({});
  }

  updateMaxFilesLimitReached(): void {
    this.maxFilesLimitReached = this.valueFormControl.value?.length === this.maxFilesLimit;
  }

  handleValueFromControlDisablingAndErrors(details: {
    status: FormControlStatus;
    errors: ValidationErrors | null;
  }): void {
    if (details.status === 'INVALID') {
      this.valueFormControl.disable();
      this.valueFormControl.setErrors(details.errors);
    } else if (details.status === 'VALID' && this.valueFormControl.disabled) {
      this.valueFormControl.enable();
    }
  }
}
