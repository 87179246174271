import { Component, Input } from '@angular/core';
import { PresentationV2 } from '../../../models';

@Component({
  selector: 'lib-skeleton-list',
  templateUrl: './skeleton-list.component.html',
  styleUrl: './skeleton-list.component.scss',
})
export class SkeletonListComponent {
  @Input() numberOfRows = 5;
  @Input() presentation: PresentationV2 = PresentationV2.listItem;
}
