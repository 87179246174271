@if (data$ | async; as data) {
  @if (data.selectedItem?.item; as item) {
    @if (detailsInfo$ | async; as detailsInfo) {
      <div class="panel-properties-content-header mh-2">
        <div class="panel-properties-content-header-title fx-row justify-space-between w-100">
          <div class="fx-row justify-center align-center">
            <mat-icon [svgIcon]="panelTabAction?.icon ?? ''" class="mr-2"></mat-icon>
            <h4 class="mat-subtitle-2">{{ panelTabAction?.title }}</h4>
          </div>
          @if (metric | showPublishButton: isAdmin : detailsInfo?.published) {
            <button lib-button buttonType="primary" (click)="publishField(item)">
              {{ "Publish" | translate }}
            </button>
          }
        </div>
        <div class="panel-properties-content-header-actions"></div>
      </div>
      @if (fieldInformationForm) {
        <div class="panel-properties-content-container ml-2 fx-col">
          <div class="fields-container pb-1">
            @if (
              (enableRefMetricsV2 && metric.reference_v2) ||
              (isAdmin && (metric.category === eMetricCategory.THIRD_PARTY || metric.reference_v2))
            ) {
              <div class="mb-2" data-testid="info-banner">
                @if (!isAdmin && detailsInfo?.active && detailsInfo?.ignore_taxonomies) {
                  <lib-info-banner
                    [message]="'Activated outside of published framework taxonomy for all fiscal years.' | translate"
                  ></lib-info-banner>
                } @else {
                  @if (
                    detailsInfo?.id | getHiddenByTaxonomyMsg: fieldVisibility : detailsInfo?.resourceType;
                    as message
                  ) {
                    <lib-info-banner [message]="message" type="warning"></lib-info-banner>
                  }
                }
              </div>
              <ng-container *ngTemplateOutlet="protocolTemplate"></ng-container>
              <lib-expandable-card
                [cardTitle]="
                  'Novisto Related Fields ({fieldsCount})' | translate: { fieldsCount: data.relatedFields.length }
                "
                [maxHeight]="expandableCardMaxContentHeight"
                data-testid="novisto-related-fields"
              >
                <div content class="expandable-card-sub-elements">
                  @for (relatedField of data.relatedFields; track relatedField) {
                    <lib-field-information-related-field
                      [currentField]="item"
                      [relatedField]="relatedField"
                      [enableDelete]="isAdmin && metric.reference_v2 | boolean"
                      (deleteRelatedField)="deleteRelatedField(item, data.relatedFields, relatedField)"
                      data-testid="related-field"
                    ></lib-field-information-related-field>
                  }
                  @if (data.relatedFields.length <= 0) {
                    <span data-testid="no-related-fields">
                      {{ "There are no related fields to display" | translate }}
                    </span>
                  }
                </div>
                @if (isAdmin && metric.reference_v2) {
                  <button
                    actions
                    lib-button
                    buttonType="primary"
                    (click)="addRelatedField(item, data.relatedFields)"
                    data-testid="add-related-field-button"
                  >
                    + {{ "Add" | translate }}
                  </button>
                }
              </lib-expandable-card>
              @if (metric.reference_v2) {
                <div class="mt-4" data-testid="taxonomies-card">
                  <lib-taxonomies-card
                    [enableAdd]="isAdmin"
                    [enableDelete]="isAdmin"
                    [taxonomies]="data.taxonomies"
                    (handleAdd)="addTaxonomies(item, data.taxonomies)"
                    (handleDelete)="deleteTaxonomyAssociation(item, data.taxonomies, $event)"
                    data-testid="taxonomies-card-content"
                  >
                  </lib-taxonomies-card>
                </div>
              }
              <lib-divider class="mv-5"></lib-divider>
            }
            @if (metric.category === eMetricCategory.CUSTOM && enableRefMetricsV2) {
              <ng-container *ngTemplateOutlet="protocolTemplate"></ng-container>
            }
            <ng-template #protocolTemplate>
              @if (showTechnicalProtocolForm) {
                <div class="mb-4 mr-2">
                  <lib-rich-text-input
                    class="mb-4 mr-2"
                    [control]="fieldInformationForm.controls.technicalProtocol"
                    [hint]="
                      'Enter your organization’s internal standards or specifications. This is visible to all users when viewing the metric, including request participants.'
                        | translate
                    "
                    [label]="'Field Technical Protocol' | translate"
                    data-testid="technical-protocol"
                  ></lib-rich-text-input>
                </div>
              }
            </ng-template>
            <div>
              <h4 class="color-grey-800 m-0">{{ "Field ID" | translate }}</h4>
              <p class="caption5">{{ detailsInfo?.id }}</p>
            </div>
            @if (detailsInfo?.coreId) {
              <div>
                <h4 class="color-grey-800 m-0">{{ "Core Field ID" | translate }}</h4>
                <p class="caption5">{{ detailsInfo?.coreId }}</p>
              </div>
            }
            @if (users$ | async; as users) {
              @if (detailsInfo?.published) {
                <div>
                  <h4 class="color-grey-800 m-0">{{ "Publish date" | translate }}</h4>
                  <p class="caption5">{{ detailsInfo?.published | date }}</p>
                </div>
              }
              @if (detailsInfo?.published && detailsInfo?.published_by) {
                <div>
                  <h4 class="color-grey-800 m-0">{{ "Published by" | translate }}</h4>
                  <p class="caption5">{{ detailsInfo?.published_by | updatedByUser: users }}</p>
                </div>
              }
            }
            @if (detailsInfo?.position) {
              <div>
                <h4 class="color-grey-800 m-0">{{ "Field Position" | translate }}</h4>
                <p class="caption5">{{ detailsInfo?.position }}</p>
              </div>
            }
          </div>
          <div>
            <lib-divider class="pb-4"></lib-divider>
            <div class="form-submit">
              <button
                lib-button
                buttonType="success"
                [disabled]="fieldInformationForm.pristine || fieldInformationForm.invalid"
                [isLoading]="!!(updating$ | async)"
                (click)="saveProperties(item)"
                [label]="'Save' | translate"
                class="mr-4"
              ></button>
              <button lib-button buttonType="link" (click)="closeTab()">{{ "Cancel" | translate }}</button>
            </div>
          </div>
        </div>
      } @else {
        <div class="m-4">
          <lib-skeleton-list [numberOfRows]="6"></lib-skeleton-list>
        </div>
      }
    }
  }
}
