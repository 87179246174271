import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Observable, Subscription, forkJoin, map, of } from 'rxjs';
import { combineLatestWith, startWith } from 'rxjs/operators';
import { ActionItemUtils, LocalStorageUtils } from '../classes';
import { DataTableExpandedRowConfig, DataTablePaginatorConfiguration } from '../data-table';
import {
  ActionItem,
  ChipGroupItem,
  CustomSearchPropertiesType,
  FilterBarOption,
  FilterBarSelection,
  FilterType,
  Framework,
  Indicator,
  IndicatorAction,
  LocalStorageKey,
  Metric,
  MetricCategory,
  MetricGroup,
  MetricSearchInitialSelection,
  MetricSearchSelection,
  Presentation,
  RelatedMetricsResourceType,
  ResourceType,
  SearchOptionFilters,
  SearchOptions,
  SortFilter,
  StandardCodes,
  TableColumn,
  TablePageEvent,
  Tag,
  Taxonomy,
  Topic,
  TopicCategory,
} from '../models';
import {
  ClientCoreService,
  ClientMetricGroupsService,
  ClientTagsService,
  ClientTaxonomiesService,
} from '../services/client';
import { TranslateService } from '../services/common';
import { MetricsIndicatorCategories, PartialMetricsMetricSort } from '../translations';
import { MetricSearchStateService } from './metric-search-state.service';
import { MetricSearchValueDefinitionsComponent } from './metric-search-value-definitions/metric-search-value-definitions.component';
import { TopicsApiService } from '../services/api-services';
import { MetricApiService } from '../services/types';
import { FeatureFlagService } from '../feature-flag';
import { FormControl } from '@angular/forms';

type IndicatorRow = Pick<Indicator, 'id' | 'code' | 'description' | 'topics'> & {
  action: Indicator;
  topics_string: string;
  related_to: string;
  compatible_with: string;
  selected: boolean;
};

interface Data {
  primaryFilters: FilterBarOption[];
  secondaryFilters: FilterBarOption[];
  isLoading: boolean;
  isLoadingNextPage: boolean;
  metrics: Indicator[];
  selectedMetrics: Indicator[];
  dataTablePaginationConfig: DataTablePaginatorConfiguration;
  selections: Record<string, boolean>;
  topicsString?: string;
  allDataLoaded?: boolean;
}

@Component({
  selector: 'lib-metric-search',
  templateUrl: './metric-search.component.html',
  styleUrls: ['./metric-search.component.scss'],
  providers: [MetricSearchStateService],
})
export class MetricSearchComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild('actionCell', { static: true }) actionCell?: TemplateRef<unknown>;
  @ViewChild('selectedCell', { static: true }) selectedCell?: TemplateRef<unknown>;
  @ViewChild('topicsCell', { static: true }) topicsCell?: TemplateRef<unknown>;
  @ViewChild('relatedToCell', { static: true }) relatedToCell?: TemplateRef<unknown>;
  @ViewChild('compatibleWithCell', { static: true }) compatibleWithCell?: TemplateRef<unknown>;
  @ViewChild('taxonomyCell', { static: true }) taxonomyCell?: TemplateRef<unknown>;

  @Input() withInfiniteScroll: boolean = false;
  @Input() actions: ActionItem[] = [];

  @Input() initialSearchOptions?: Partial<SearchOptions>;
  @Input() initialSelections?: MetricSearchInitialSelection;
  @Input() enableValueDefinitionSelection: boolean = false;
  @Input() defaultSortFilter: SortFilter.description | SortFilter.start = SortFilter.start;
  @Input() isCheckable: boolean = true;
  @Input() withQuestionsSyntax: boolean = false;
  @Input() withTopicFilter: boolean = true;
  @Input() withCategoryFilter: boolean = true;
  @Input() withStandardCodeFilter: boolean = true;
  @Input() withTaxonomyFilter: boolean = true;
  @Input() withTagFilter: boolean = true;
  @Input() withMetricGroupFilter: boolean = true;
  @Input() withSort: boolean = false;
  @Input() withRelatedToColumn: boolean = true;
  @Input() withTopicColumn: boolean = true;
  @Input() showRowSelection: boolean = false;
  @Input() filters: SearchOptionFilters = {};
  @Input() withArchivedMetricsFilter: boolean = false;
  @Input() withThirdPartyMetricsFilter: boolean = false;
  @Input() withAISearch: boolean = false;

  @Output() selectionChange = new EventEmitter<MetricSearchSelection[]>();
  @Output() indicatorClick = new EventEmitter<Metric>();
  @Output() relatedMetricClick = new EventEmitter<ActionItem<Indicator | StandardCodes>>();
  @Output() actionClick = new EventEmitter<IndicatorAction>();
  @Output() searchOptionsChange = new EventEmitter<SearchOptions | undefined>();

  public readonly expandedRowConfig?: DataTableExpandedRowConfig = {
    component: MetricSearchValueDefinitionsComponent,
    componentProperties: [{ inputName: 'metricId', rowPropertyName: 'metric_id' }],
  };

  filters$?: Observable<{ primary: FilterBarOption[]; secondary: FilterBarOption[] }>;
  isLoading$: Observable<boolean> = this.metricSearchStateService.isLoading$;
  isLoadingNextPage$: Observable<boolean> = this.metricSearchStateService.isLoadingNextPage$;
  metrics$: Observable<Indicator[]> = this.metricSearchStateService.metrics$;
  selections$: Observable<Record<string, boolean>> = this.metricSearchStateService.selections$;
  dataTablePaginationConfig$: Observable<DataTablePaginatorConfiguration> =
    this.metricSearchStateService.dataTablePaginationConfig$;
  allDataLoaded$: Observable<boolean> = this.metricSearchStateService.allDataLoaded$;

  data$?: Observable<Data>;

  metricTableColumns: TableColumn<IndicatorRow>[] = [];
  public aiSearchControl = new FormControl<boolean>(false, { nonNullable: true });

  readonly ePresentation = Presentation;

  private enableRefMetricsV2FF: boolean = false;
  private subscriptions: Subscription[] = [];
  private readonly aiSearchUnsupportedFilters = [
    String(ResourceType.taxonomy),
    String(ResourceType.standard_codes),
    String(ResourceType.sort),
  ];

  constructor(
    private translateService: TranslateService,
    private clientCoreService: ClientCoreService,
    private topicsApiService: TopicsApiService,
    private tagsService: ClientTagsService,
    private clientMetricGroupsService: ClientMetricGroupsService,
    private metricSearchStateService: MetricSearchStateService,
    private clientTaxonomiesService: ClientTaxonomiesService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly metricService: MetricApiService,
  ) {}

  public ngOnInit(): void {
    const aiSearchDisabled = LocalStorageUtils.getFromStorage<boolean>(LocalStorageKey.AI_SEARCH_DISABLED_SESSION_KEY);
    this.aiSearchControl.setValue(this.withAISearch && !aiSearchDisabled);
    this.enableRefMetricsV2FF = this.featureFlagService.areAnyFeatureFlagsEnabled(['enable_ref_metrics_v2']);

    const tags$ = this.withTagFilter
      ? this.tagsService.list().pipe(map((res) => ActionItemUtils.resourcesToActionItem(res.data, ResourceType.tag)))
      : of([]);
    const topics$ = this.withTopicFilter
      ? this.topicsApiService
          .listTopicCategories()
          .pipe(map((res) => ActionItemUtils.resourcesToActionItem(res.data, ResourceType.topic)))
      : of([]);
    const standardCodes$ = this.withStandardCodeFilter
      ? this.clientCoreService
          .listFrameworks('standard_code')
          .pipe(map((res) => ActionItemUtils.resourcesToActionItem(res.data, ResourceType.standard_codes)))
      : of([]);
    const groups$ = this.withMetricGroupFilter
      ? this.clientMetricGroupsService
          .listMetricGroups()
          .pipe(map((res) => ActionItemUtils.resourcesToActionItem(res.data, ResourceType.metric_group)))
      : of([]);
    const taxonomies$ =
      this.withTaxonomyFilter && this.enableRefMetricsV2FF
        ? this.clientTaxonomiesService
            .getFavoriteTaxonomies(true)
            .pipe(
              map((res) =>
                ActionItemUtils.resourcesToActionItem(res.data, ResourceType.taxonomy).sort((a, b) =>
                  a.title.localeCompare(b.title),
                ),
              ),
            )
        : of([]);

    this.filters$ = forkJoin([topics$, standardCodes$, tags$, groups$, taxonomies$]).pipe(
      map(([topics, standardCodes, tags, groups, taxonomies]) =>
        this.getFilterOptions(topics, standardCodes, tags, groups, taxonomies),
      ),
    );

    this.data$ = this.filters$.pipe(
      combineLatestWith(
        this.isLoading$,
        this.isLoadingNextPage$,
        this.metrics$,
        this.dataTablePaginationConfig$,
        this.selections$,
        this.allDataLoaded$,
        this.aiSearchControl.valueChanges.pipe(startWith(this.aiSearchControl.value)),
      ),
      map(
        ([
          filters,
          isLoading,
          isLoadingNextPage,
          metrics,
          dataTablePaginationConfig,
          selections,
          allDataLoaded,
          aiSearch,
        ]) => {
          let secondary = filters.secondary;

          if (this.withAISearch && aiSearch) {
            secondary = secondary.filter((f) => !this.aiSearchUnsupportedFilters.includes(f.id));
          }

          const allMetrics: Indicator[] = this.addTopicsStringToMetrics(metrics);
          const selectedMetrics: Indicator[] = allMetrics.filter(
            (metric: Indicator) => metric.metric_id && selections[metric.metric_id],
          );

          return {
            primaryFilters: filters.primary,
            secondaryFilters: secondary,
            isLoading,
            isLoadingNextPage,
            metrics: allMetrics,
            selectedMetrics,
            dataTablePaginationConfig,
            selections,
            allDataLoaded,
          };
        },
      ),
    );

    this.metricSearchStateService.initialize(
      this.withInfiniteScroll,
      {
        id: this.defaultSortFilter,
        title: this.defaultSortFilter === SortFilter.start ? 'Last updated' : 'Alphabetical',
      },
      this.filters,
      this.initialSelections,
      this.fetchCustomProperties(),
      this.withAISearch,
      this.initialSearchOptions,
    );
    this.metricSearchStateService.loadMetrics();
    this.subscriptions.push(
      this.metricSearchStateService.selections$.subscribe(() => {
        this.selectionChange.emit(this.metricSearchStateService.getSelections());
      }),
    );

    this.subscriptions.push(
      this.metricSearchStateService.searchOptions$.subscribe((searchOptions) => {
        this.searchOptionsChange.emit(searchOptions);
      }),
    );

    this.subscriptions.push(
      this.aiSearchControl.valueChanges.subscribe((value) => {
        this.metricSearchStateService.onCustomPropertiesChange(this.fetchCustomProperties());
        LocalStorageUtils.addToStorage(LocalStorageKey.AI_SEARCH_DISABLED_SESSION_KEY, !value);
      }),
    );
  }

  public ngAfterContentInit(): void {
    this.setupColumns();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public get selections(): MetricSearchSelection[] {
    return this.metricSearchStateService.getSelections();
  }

  public onSearchChange(searchQuery: string, enterKeyup = false): void {
    if (!this.aiSearchControl.value || !searchQuery || enterKeyup) {
      this.metricSearchStateService.onSearchChange(searchQuery);
    }
  }

  public onFilterChange(options: FilterBarSelection[]): void {
    this.metricSearchStateService.onFilterChange(options);
  }

  public onPageChange(event?: TablePageEvent): void {
    this.metricSearchStateService.onPageChange(event);
  }

  public onChipsClick(action: ActionItem<Indicator | StandardCodes>): void {
    switch (action.id) {
      case RelatedMetricsResourceType.standard_code:
      case RelatedMetricsResourceType.core_metric:
        this.relatedMetricClick.emit(action);
        break;
      case RelatedMetricsResourceType.imported_metric:
        const metric = action.item as Metric;
        if (metric.category === MetricCategory.THIRD_PARTY) {
          this.relatedMetricClick.emit(action);
        } else if (metric.indicator_id) {
          this.indicatorClick.emit(metric);
        } else if (!metric.indicator_id) {
          this.metricService
            .getMetric(metric.id, {
              load_value_definition_groups: false,
              load_value_definitions: false,
              load_related_equivalent: false,
              load_related_core_equivalent: false,
              load_validators: false,
              load_conditional_triggers: false,
              load_value_definition_standard_codes: false,
              load_standard_codes: false,
              load_tags: false,
              load_topics: false,
              load_framework: false,
              load_industries: false,
            })
            .subscribe((result) => {
              this.indicatorClick.emit(result.data);
            });
        }
        break;
      default:
        break;
    }
  }

  public onCheckChanged(metrics: Indicator[], selection?: string, all?: boolean) {
    if (typeof all === 'boolean') {
      metrics.forEach((i) => this.metricSearchStateService.handleSelection(i.id, { force: all }));
    } else if (selection) {
      this.metricSearchStateService.handleSelection(selection, {
        fetchValueDefinitions: this.enableValueDefinitionSelection,
      });
    }
  }

  public onSelectionChanged(metric: Metric): void {
    this.metricSearchStateService.handleSelection(metric.id, { singleSelection: true });
    this.indicatorClick.emit(metric);
  }

  public topicToChip(topic: Topic): ChipGroupItem {
    return { outline: false, text: topic.name, variant: 'light' };
  }

  public stopDefaultClickAction(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public refresh(): void {
    this.metricSearchStateService.refresh();
  }

  private addTopicsStringToMetrics(metrics: Indicator[]): Indicator[] {
    metrics.map((metric) => {
      metric.topicsString = metric.topics?.map((topic) => topic.name).join(', ');
      return metric;
    });
    return metrics;
  }

  private setupColumns(): void {
    this.metricTableColumns = [
      {
        name: this.translateService.instant(this.withQuestionsSyntax ? 'Question Number' : 'Metric Code'),
        dataKey: 'code',
        width: '15%',
      },
      {
        name: this.translateService.instant(this.withQuestionsSyntax ? 'Question' : 'Metric Description'),
        dataKey: 'description',
        maxCharCount: 50,
      },
    ];

    if (this.withTopicFilter && this.withTopicColumn) {
      this.metricTableColumns.push({
        name: this.translateService.instant('Topics'),
        dataKey: 'topicsString',
        width: '20%',
        maxCharCount: 40,
      });
    }

    if (this.withRelatedToColumn && !this.enableRefMetricsV2FF) {
      this.metricTableColumns.push({
        name: this.translateService.instant('Related To'),
        dataKey: 'relatedTo',
        cellTemplate: this.relatedToCell,
        width: '15%',
        maxCharCount: 40,
      });
    }

    if (this.enableRefMetricsV2FF && this.withTaxonomyFilter) {
      this.metricTableColumns.push({
        name: this.translateService.instant('Framework Taxonomy'),
        dataKey: 'frameworkTaxonomy',
        cellTemplate: this.taxonomyCell,
        width: '20%',
        maxCharCount: 40,
      });
    } else if (!this.enableRefMetricsV2FF && this.withStandardCodeFilter) {
      this.metricTableColumns.push({
        name: this.translateService.instant('Standard Code'),
        dataKey: 'compatibleWith',
        cellTemplate: this.compatibleWithCell,
        width: '20%',
        maxCharCount: 40,
      });
    }

    if (this.actions.length) {
      this.metricTableColumns.push({
        name: this.translateService.instant('Actions'),
        dataKey: 'action',
        cellTemplate: this.actionCell,
        width: '5%',
        noHeader: true,
      });
    }
  }

  private getFilterOptions(
    topics: ActionItem<TopicCategory>[],
    standardCodes: ActionItem<Framework>[],
    tags: ActionItem<Tag>[],
    groups: ActionItem<MetricGroup>[],
    taxonomies: ActionItem<Taxonomy>[],
  ): { primary: FilterBarOption[]; secondary: FilterBarOption[] } {
    const primary: FilterBarOption[] = [];
    const secondary: FilterBarOption[] = [];

    if (this.withTopicFilter) {
      secondary.push({
        title: this.translateService.instant('Topic'),
        id: ResourceType.topic,
        optionType: FilterType.searchList,
        displayAll: true,
        options: topics,
        selectedValue: this.initialSearchOptions?.filters?.topic?.title,
      });
    }

    if (this.withCategoryFilter) {
      const metricCategories: Partial<typeof MetricsIndicatorCategories> = { ...MetricsIndicatorCategories };
      if (!this.withArchivedMetricsFilter) {
        delete metricCategories[MetricCategory.ARCHIVED];
      }
      if (!this.withThirdPartyMetricsFilter) {
        delete metricCategories[MetricCategory.THIRD_PARTY];
      }
      const options = this.translateService.listResources(metricCategories as typeof MetricsIndicatorCategories);
      const defaultOptions = options.filter((o) =>
        [String(MetricCategory.CUSTOM), MetricCategory.REFERENCE].includes(o.id),
      );
      const selectedOptions = this.initialSearchOptions?.multi_select_filters?.category || defaultOptions;
      const multiSelectDefaultValues = selectedOptions.map((o) => o.id);

      primary.push({
        multiSelect: true,
        title: this.translateService.instant('Category'),
        id: ResourceType.category,
        optionType: FilterType.list,
        displayAll: true,
        options: options.map((o) => ({ ...o, selected: multiSelectDefaultValues.includes(o.id) })),
        multiSelectDefaultValues,
      });
    }

    if (this.enableRefMetricsV2FF && this.withTaxonomyFilter) {
      secondary.push({
        title: this.translateService.instant('Framework Taxonomy'),
        id: ResourceType.taxonomy,
        optionType: FilterType.searchList,
        displayAll: true,
        options: taxonomies,
        selectedValue: this.initialSearchOptions?.filters?.taxonomy?.title,
        secondaryHeaders: [
          { id: 'outside_taxonomy', title: this.translateService.instant('Imported outside of taxonomy') },
        ],
      });
    } else if (this.withStandardCodeFilter) {
      secondary.push({
        title: this.translateService.instant('Standard Code'),
        id: ResourceType.standard_codes,
        optionType: FilterType.searchList,
        displayAll: true,
        options: standardCodes,
        selectedValue: this.initialSearchOptions?.filters?.standard_codes?.title,
      });
    }

    if (this.withTagFilter) {
      secondary.push({
        title: this.translateService.instant('Tags'),
        id: ResourceType.tag,
        optionType: FilterType.searchList,
        displayAll: true,
        options: tags,
        selectedValue: this.initialSearchOptions?.filters?.tag?.title,
      });
    }

    if (this.withMetricGroupFilter) {
      secondary.push({
        title: this.translateService.instant('Group'),
        id: 'metric_groups',
        optionType: FilterType.list,
        displayAll: true,
        options: groups,
        selectedValue: this.initialSearchOptions?.filters?.metric_groups?.title,
      });
    }

    if (this.withSort) {
      secondary.push({
        icon: 'sort',
        title: this.translateService.instant('Sort'),
        id: ResourceType.sort,
        optionType: FilterType.list,
        displayAll: false,
        options: this.translateService.listResources(PartialMetricsMetricSort),
        selectedValue: this.initialSearchOptions?.filters?.sort?.title,
        defaultValue: this.defaultSortFilter === SortFilter.start ? 'Last updated' : 'Alphabetical',
      });
    }

    return { primary, secondary };
  }

  onAction(action: ActionItem, indicator: Indicator) {
    this.actionClick.emit({ action_id: action.id, indicator_id: indicator.id });
  }

  private fetchCustomProperties(): CustomSearchPropertiesType {
    return {
      load_taxonomies: Boolean(this.enableRefMetricsV2FF && this.withTaxonomyFilter),
      ai_search: Boolean(this.withAISearch && this.aiSearchControl.value),
    };
  }
}
