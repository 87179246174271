import { Component, Input } from '@angular/core';
import { PresentationV2 } from '../../models';

@Component({
  selector: 'lib-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrl: './skeleton.component.scss',
})
export class SkeletonComponent {
  @Input() presentation: PresentationV2 = PresentationV2.card;

  readonly ePresentation = PresentationV2;
}
