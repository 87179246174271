<div [ngClass]="{ inactive: isDeactivated }" data-testid="metricTableDetails">
  <div class="table-title">
    <span class="heading">{{ metricTableGroup.title }}</span>
  </div>

  <div class="resizing-container">
    <div class="table-container">
      <table data-testid="metricTable" class="metric-table mat-elevation-z1">
        <thead>
          <tr class="metric-table-row">
            <th class="sticky-column header">
              <div class="grid-column">
                @for (value of contextHeaderValues; track value) {
                  <p class="fx-row align-center">{{ value.label }}</p>
                }
              </div>
            </th>
            @for (value of inputHeaderValues; track value) {
              <th class="metric-table-row--header-cell">
                {{ value.label }} {{ value.type_details?.units | unitSymbol: true }}
              </th>
            }
          </tr>
        </thead>

        <tbody>
          @for (group of tableDataSource; track group) {
            <tr class="metric-table-row">
              <td class="sticky-column">
                <div class="grid-column context">
                  @for (contextVD of group.value_definitions | filter: filterLabelTypeValue; track contextVD) {
                    <p>
                      {{ contextVD.type_details.value }}
                    </p>
                  }
                </div>
              </td>
              @for (inputVD of group.value_definitions | filter: filterValuesExceptLabelType; track inputVD) {
                <td class="metric-table-row--cell cell-container">
                  <div class="input-column-field">{{ " " }}</div>
                </td>
              }
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>
