import { Source } from '../entities';

export type BaseSourceCreateRequest = Omit<Source, 'id' | 'industries' | 'active' | 'slug' | 'start_fiscal_year'>;

export type BaseSourceUpdateRequest = BaseSourceCreateRequest;

export const MAXIMUM_SOURCE_DEPTH = 4;
export const MAXIMUM_BYPASS_DEPTH = 3;
export const MAXIMUM_CONSOLIDATED_PARENT_DEPTH = 2;
export const MAXIMUM_REGULAR_SOURCE_PARENT_DEPTH = 3;

export interface ConvertBusinessUnitValidation {
  valid: boolean;
}
