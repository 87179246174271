<div class="card-dummies-container" [ngClass]="{ loaded: docLoaded }">
  <div class="h-100 card-item-dummies">
    <lib-skeleton data-testid="loadingDummies" [presentation]="ePresentationV2.bigCard"></lib-skeleton>
  </div>
  <a class="card">
    <div class="card-content">
      <div class="pr-2">
        <div class="caption2 color-grey-800 line-clamp">
          @if (document?.category) {
            <span> {{ document?.category }}</span>
          }
        </div>
        <div class="mat-body-2 color-primary-900 text-clamp text-clamp-2 mt-1">
          {{ docFileName || ("Document link" | translate) }}
        </div>
      </div>
      <div data-testid="fileIcon" class="file-icon" [ngClass]="'file-' + (documentIcon || 'no-fill')">
        <mat-icon [svgIcon]="documentIcon"></mat-icon>
      </div>
    </div>
    <div class="card-footer">
      <a class="btn btn-primary" (click)="downloadDocument()" [ngClass]="{ 'btn-disabled': !documentId }">{{
        "Download" | translate
      }}</a>
    </div>
  </a>
</div>
