import Quill from 'quill';

import 'quill-mention/autoregister';

const MentionBlot: any = Quill.import('blots/mention');

export class StyledMentionBlot extends MentionBlot {
  static create(item: any) {
    const node = super.create();
    if (item.isCurrentUser === 'true') {
      node.classList.add('self-tag');
    } else {
      node.classList.add('others-tag');
    }
    node.innerText = `@${item.value}`;
    return node;
  }
}
StyledMentionBlot.blotName = 'styled-mention';
Quill.register(StyledMentionBlot, true);
