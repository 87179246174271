import { CreatedUpdatedEntity, Framework, StandardCodes } from './entities';

export interface MinimalTaxonomy extends CreatedUpdatedEntity {
  id: string;
  label: string;
}

export enum TaxonomyCategory {
  node = 'node',
  standard_code = 'standard_code',
  third_party_metric = 'third_party_metric',
}

export enum FavoriteTaxonomyStatus {
  NEW = 'new',
  PUBLISHED = 'published',
  PROCESSING = 'processing',
  ERROR = 'error',
}

export interface Taxonomy extends MinimalTaxonomy {
  is_leaf: boolean;
  end?: string;
  parent_id?: string | null;
  standard_codes?: StandardCodes[];
  start_date?: string;
  children?: Taxonomy[];
  framework_id: string;
  category: TaxonomyCategory;
  code: string;
  description?: string;
  end_date?: string;
  published_date?: string;
  guidance?: string;
  disclaimers?: string;
  active: boolean;
  is_client_selectable: boolean;
  children_mutually_exclusive: boolean;
  framework?: Framework;
  value_definition_ids?: string[];
  metric_table_definition_ids?: string[];
}

export interface FavoriteTaxonomy extends Taxonomy {
  frequency_code?: string;
  status?: FavoriteTaxonomyStatus;
}

export interface TaxonomiesPayload {
  label: string;
  is_leaf?: boolean;
  parent_id?: string | null;
  start?: string | null;
  end?: string | null;
  code?: string | null;
  lowest_visible_level?: boolean;
}

export interface AssociateTaxonomiesRequest {
  taxonomies: string[];
}

export interface ListTaxonomiesRequest {
  complete_frameworks: boolean;
  order_by?: string;
  order_by_direction?: 'asc' | 'desc';
  page?: number;
  page_size?: number;
  search_term?: string;
  taxonomy_ids: string[];
}

export interface MetricTaxonomies {
  favorite_taxonomies: MinimalTaxonomyTree[];
  leaf_taxonomies: MinimalTaxonomyTree[];
  lowest_visible_taxonomies: MinimalTaxonomyTree[];
}

export interface MinimalTaxonomyTree {
  id: string;
  parent_id?: string;
  lowest_visible_level: boolean;
  is_leaf: boolean;
  children?: MinimalTaxonomyTree[];
  label: string;
  is_client_selectable: boolean;
  children_mutually_exclusive: boolean;
  description: string;
  selected?: boolean;
  expanded?: boolean;
  disabled?: boolean;
}

export interface TaxonomyCollectionStatus {
  taxonomy_id: string;
  data_points_total: number;
  data_points_complete: number;
  completion_percentage: number;
}

export interface FrameworkTaxonomiesCollection {
  framework_id: string;
  taxonomies_collection_status: TaxonomyCollectionStatus[];
}

export interface AggregatedFrameworkStatus {
  taxonomies_selected?: number;
  data_points_complete: number;
  data_points_total: number;
  completion_percentage: number;
}
