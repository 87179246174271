@if (presentation === ePresentation.bigCard) {
  <div class="big-card">
    <div>
      <p-skeleton shape="circle" size="4rem" />
      <div>
        <p-skeleton width="10rem" />
        <p-skeleton width="5rem" />
        <p-skeleton height=".5rem" />
      </div>
    </div>

    <p-skeleton class="mid-section" width="100%" height="100%" />

    <div>
      <p-skeleton width="4rem" height="2rem" />
      <p-skeleton width="4rem" height="2rem" />
    </div>
  </div>
}

@if (presentation === ePresentation.card) {
  <div class="card">
    <p-skeleton width="25%" height="1rem" />
    <p-skeleton width="100%" height="1rem" />
  </div>
}

@if (presentation === ePresentation.listItem) {
  <div class="listItem">
    <p-skeleton shape="circle" size="4rem" />
    <div>
      <p-skeleton width="100%" />
      <p-skeleton width="75%" />
    </div>
  </div>
}
