@if ((!attachmentEnabled && attachmentOptional) || maxFilesLimitReached) {
  <lib-form-field-label
    [label]="valueFormControl | fileLabel: false"
    [control]="valueFormControl"
  ></lib-form-field-label>
}

@if (!maxFilesLimitReached && attachmentEnabled) {
  <lib-metric-editor-file-upload-card
    [valueFormControl]="valueFormControl"
    [documentContext]="documentContext"
    [messages]="messages"
    [disabled]="valueFormControl.disabled || addingNewFile"
    (addFileDoc)="addFileDoc($event)"
    (addFileValue)="addFileValue($event)"
  ></lib-metric-editor-file-upload-card>
}

@if (filesLoaded) {
  <div class="file-cards-container" @fadeIn>
    @for (fileValue of fileValues; track fileValue; let i = $index) {
      <lib-metric-editor-file-card
        [fileValue]="fileValue"
        [valueFormControl]="valueFormControl"
        [fileDoc]="fileValue | findFileDocument: fileDocumentsList"
        [documentContext]="documentContext"
        [status]="valueFormControl.status"
        [index]="i"
        (deleteFileValue)="deleteFileValue($event)"
        (disableValueFormControl)="handleValueFromControlDisablingAndErrors($event)"
      ></lib-metric-editor-file-card>
    }
  </div>
} @else {
  <div class="dummies-container w-100 h-100 fx-col" @fadeOut>
    <div class="h-100 pt-2" data-testid="loadingDummies">
      @for (i of [1, 2]; track i) {
        <lib-skeleton [presentation]="ePresentationV2.bigCard"></lib-skeleton>
      }
    </div>
  </div>
}

@if (!maxFilesLimitReached && attachmentOptional) {
  @if (attachmentEnabled) {
    <div class="mt-4 mb-n-1">
      <strong>{{ "OR" | translate }}</strong>
    </div>
  }
  <div class="mv-4">
    <button
      lib-button
      buttonType="link"
      class="pl-0"
      (click)="addFileURLValue()"
      [ngClass]="{ disabled: valueFormControl.disabled || addingNewFile }"
    >
      {{ "+ Add URL" | translate }}
    </button>
  </div>
}

@if (maxFilesLimitReached) {
  <div class="mv-4">
    <lib-info-banner type="warning" [message]="'Maximum number of attachments have been reached.' | translate">
    </lib-info-banner>
  </div>
}
