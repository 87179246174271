@if (data$ | async; as data) {
  <lib-filter-bar
    [initialSearch]="initialSearchOptions?.query?.keywords"
    [primaryFilters]="data.primaryFilters"
    [secondaryFilters]="data.secondaryFilters"
    [withSearchBar]="true"
    [searchBarPlaceholder]="
      (aiSearchControl.value ? 'Type and hit enter to search metrics' : 'Search metrics') | translate
    "
    (onSearchChanged)="onSearchChange($event)"
    (searchTriggered)="onSearchChange($event, true)"
    (onFilterChanged)="onFilterChange($event)"
  >
    @if (withAISearch) {
      <div class="ai-search-container ml-4">
        <lib-slide-toggle [control]="aiSearchControl" [label]="'AI Search' | translate"></lib-slide-toggle>
      </div>
    }
  </lib-filter-bar>

  <div class="metric-search-container">
    <lib-table-grid
      [columns]="metricTableColumns"
      [values]="data.metrics"
      [isResizable]="true"
      [isExpandable]="enableValueDefinitionSelection"
      [expandedRowConfig]="enableValueDefinitionSelection ? expandedRowConfig : undefined"
      [isLoading]="data.isLoading"
      [totalItemCount]="data.dataTablePaginationConfig.total"
      [enableCheckboxSelection]="isCheckable"
      [selectAllOption]="!enableValueDefinitionSelection"
      [isWrapping]="true"
      [selection]="data.selectedMetrics"
      (rowSelected)="onSelectionChanged($event)"
      (pageChanged)="onPageChange($event)"
      (rowChecked)="onCheckChanged(data.metrics, $event.rowItem.metric_id)"
    ></lib-table-grid>
  </div>
}

<ng-template #actionCell let-row="row">
  <button
    lib-button
    libClickStopPropagation
    class="ml-2 static"
    data-testid="moreActions"
    [matMenuTriggerFor]="menu"
    [matMenuTriggerData]="{ row: row }"
  >
    <mat-icon svgIcon="more"></mat-icon>
  </button>
</ng-template>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-aliasMenuItems="row">
    @for (action of actions; track action) {
      <ng-container
        *ngTemplateOutlet="actionItem; context: { action: action, aliasMenuItems: aliasMenuItems }"
      ></ng-container>
    }
  </ng-template>
</mat-menu>

<ng-template #showMoreButton let-items="items">
  @if (items.length) {
    <a
      class="btn btn-xs bkg-grey-200 btn-more-link"
      [ngClass]="{ active: trigger.menuOpen }"
      [matMenuTriggerFor]="moreMenu"
      #trigger="matMenuTrigger"
      [matMenuTriggerData]="{ items: items }"
      (click)="stopDefaultClickAction($event)"
    >
      + {{ items.length }}
    </a>
  }
</ng-template>

<mat-menu #moreMenu="matMenu">
  <ng-template matMenuContent let-items="items">
    <div class="ph-3 pv-2">
      @for (item of items; track item) {
        <div class="mb-1">{{ item.name }}</div>
      }
    </div>
  </ng-template>
</mat-menu>

<ng-template #relatedToCell let-row="row">
  <div class="long-text-wrapper">
    <lib-metric-card-related-metrics
      [metricActionItem]="row | getRelatedMetricItems"
      (handleSelect)="onChipsClick($event)"
    ></lib-metric-card-related-metrics>
  </div>
</ng-template>

<ng-template #compatibleWithCell let-row="row">
  <div class="long-text-wrapper">
    <lib-metric-card-related-metrics
      [metricActionItem]="row | getCompatibleMetricsItems"
      (handleSelect)="onChipsClick($event)"
    ></lib-metric-card-related-metrics>
  </div>
</ng-template>

<ng-template #taxonomyCell let-row="row">
  <div class="long-text-wrapper">
    <lib-show-more-chip-cards
      [chipItems]="row | getTaxonomyChipItems"
      [disableChipAction]="true"
    ></lib-show-more-chip-cards>
  </div>
</ng-template>

<ng-template #actionItem let-action="action" let-row="aliasMenuItems">
  @if (action.id === "separator") {
    <lib-divider></lib-divider>
  }
  @if (action.id !== "separator") {
    <a
      mat-menu-item
      [ngClass]="{ 'color-error': action.id === 'delete' }"
      [disabled]="action.disabled"
      (click)="onAction(action, row)"
    >
      <mat-icon [svgIcon]="action.icon!" class="icon-sm"></mat-icon>
      <span>{{ action.title }}</span>
    </a>
  }
</ng-template>
