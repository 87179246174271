<div class="item-card mt-1" [ngClass]="cardClasses" role="presentation" [attr.aria-label]="item.title">
  <div class="item-card-action">
    @if (actionTmpl) {
      <ng-container [ngTemplateOutlet]="actionTmpl" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    }
  </div>
  <div class="item-card-details">
    @if (item.title; as title) {
      <b class="text-clamp text-clamp-1 m-0" [title]="title">
        {{ title }}
        @if (afterTitleTmpl) {
          <ng-container
            [ngTemplateOutlet]="afterTitleTmpl"
            [ngTemplateOutletContext]="{ $implicit: item }"
          ></ng-container>
        }
      </b>
    }
    @if (item.description; as description) {
      <p class="item-card-description text-clamp text-clamp-2 m-0" [title]="description">
        {{ description }}
      </p>
    }
    @if (item.subtitle; as subtitle) {
      <p class="item-card-subtitle text-clamp text-clamp-2 m-0" [title]="subtitle">
        {{ subtitle }}
      </p>
    }
    @if (extraContentTmpl) {
      <ng-container
        [ngTemplateOutlet]="extraContentTmpl"
        [ngTemplateOutletContext]="{ $implicit: item }"
      ></ng-container>
    }
  </div>
  @if (menuItems) {
    <lib-popup-menu
      class="item-card-menu"
      [disabled]="menuDisabled"
      [menuItems]="menuItems"
      (action)="menuAction.emit({ action: $event, item })"
    ></lib-popup-menu>
  }
</div>
