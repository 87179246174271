@if (control) {
  <div libFormField [size]="size" [control]="control" [dataTestId]="'rich-text-editor'">
    <lib-form-field-label
      [label]="label"
      [control]="control"
      [id]="_inputId"
      [fieldInfo]="fieldInfo"
    ></lib-form-field-label>
    <div [id]="_inputId" class="rich-text-formfield">
      @if (formControl) {
        <p-editor
          #pEditor
          [attr.aria-labelledby]="_inputId"
          [formControl]="formControl"
          [placeholder]="placeholder"
          [readonly]="control.disabled"
          [bounds]="'#' + _inputId"
          (onInit)="onEditorInit($event)"
          [ngClass]="{ error: control.touched && control.invalid }"
        >
          <ng-template pTemplate="header">
            <span class="ql-formats">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button type="button" class="ql-italic" aria-label="Italic"></button>
              <button type="button" class="ql-list" value="bullet" aria-label="Unordered List"></button>
              <button type="button" class="ql-list" value="ordered" aria-label="Ordered List"></button>
              <button type="button" class="ql-link" aria-label="Insert Link"></button>
            </span>
          </ng-template>
        </p-editor>
      }
    </div>
    @if (hint && (control.valid || !control.touched)) {
      <mat-hint>{{ hint }}</mat-hint>
    }
    @if (!control.valid && control.touched) {
      @for (error of control.errors | errorKeys; track error) {
        <mat-error> {{ errorMessages[error] }}</mat-error>
      }
    }
  </div>
}
