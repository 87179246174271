<lib-search-bar
  [itemType]="eItemType.docs_doc"
  [customFilters]="filters"
  [mode]="'select'"
  [customProperties]="properties"
  [lockedFilters]="lockedFilters"
  (searchOptionsChange)="change($event)"
  [searchPlaceholder]="'Search by name' | translate"
  [filterArgs]="filterArgs"
  [searchFilterConfig]="searchFilterConfig"
></lib-search-bar>

<div class="doc-items">
  <div
    [class]="'panel-search layout-grid' + ' presentation-' + ePresentation.doc + ' grid-3'"
    [ngClass]="{ 'item-selected': selectedItemID }"
  >
    <div class="panel-collection">
      @if (!loaded) {
        <div class="collection collection-dummies">
          @for (dummy of dummies; track dummy) {
            <lib-skeleton [presentation]="ePresentationV2.bigCard"></lib-skeleton>
          }
        </div>
      }
      <div class="h-100 w-100" [ngClass]="{ 'collection collection-items': itemCollection?.items?.length }">
        @for (item of itemCollection?.items; track item) {
          <div
            class="card-container"
            [ngClass]="{
              active: activeItem === item || selectedItem === item,
              selected: selectedItem === item,
            }"
            (mouseover)="onMouseOver(item)"
            (mouseout)="onMouseOut()"
          >
            <lib-document-card [item]="item" (action)="selectItem($event)"> </lib-document-card>
          </div>
        }
        @if (itemCollection?.items?.length === 0) {
          <div class="h-100 collection-empty">
            @if (noData) {
              <div class="h-100">
                <ng-content></ng-content>
              </div>
            }
            @if (!noData) {
              <div class="h-100">
                <lib-empty-results [displayData]="emptyResults"></lib-empty-results>
              </div>
            }
          </div>
        }
        <div #spinner class="spinner">
          <lib-spinner [diameter]="20"></lib-spinner>
        </div>
        <div class="grid-end"></div>
      </div>
    </div>
  </div>
</div>
