@if (data$ | async; as data) {
  @if (data.metric) {
    @if (!selectedTable) {
      <div class="metric-info-container">
        <p class="metric-code">{{ data.metric.code }}</p>
        <p class="metric-description">{{ data.metric.description }}</p>
      </div>
      <div class="search-container">
        @if (data.tableFilters) {
          <lib-filter-bar
            [secondaryFilters]="data.tableFilters"
            [withSearchBar]="true"
            [searchBarPlaceholder]="'Search field' | translate"
            (onSearchChanged)="onSearchChanged($event)"
            (onFilterChanged)="onFilterChange($event)"
          ></lib-filter-bar>
        }
      </div>
      <lib-data-table-remote-data
        class="table-wrapper"
        [enableRowSelection]="enableRowSelection"
        [selectAllOption]="false"
        [selections]="selections"
        [tableColumns]="valueDefinitionTableColumns"
        [tableData]="data.filteredValueDefinitionsWithFieldTypeRows"
        [isPaginable]="false"
        (checkChanged)="checkChanged($event, data.filteredValueDefinitionsWithFieldTypeRows)"
        (selectRow)="enableRowSelection ? undefined : selectValueDefinition($event)"
        [showRowSelection]="!enableRowSelection"
      >
      </lib-data-table-remote-data>
    }
    @if (selectedTable) {
      @if (metricTableValueSelection === eMetricTableValueSelectionChoice.tableColumn) {
        <lib-select-metric-table-column-definition
          [metricTableDefinition]="selectedTable"
          (metricTableColumnDefinitionSelected)="selectTableInputColumn($event)"
          (cancel)="selectedTable = undefined"
        ></lib-select-metric-table-column-definition>
      }
      @if (metricTableValueSelection === eMetricTableValueSelectionChoice.tableCell) {
        <lib-select-metric-table-cell-value-definition
          [metricTableDefinition]="selectedTable"
          (cancel)="selectedTable = undefined"
          [metric]="data.metric"
          (metricTableValueDefinitionSelected)="selectValueDefinitionFromTableCell($event)"
        ></lib-select-metric-table-cell-value-definition>
      }
    }
  } @else {
    <lib-skeleton-table
      [columns]="valueDefinitionTableColumns"
      [numberOfRow]="4"
      data-testid="dummy-matTable"
    ></lib-skeleton-table>
  }
}

<ng-template #valueDefinitionCell let-row="row">
  <div class="field-type-cell">
    <div>{{ row?.fieldTypeText }}</div>

    @if (!enableRowSelection && row?.fieldType === eValueDefinitionFieldType.table) {
      <mat-icon svgIcon="arrow-head-right" data-testid="table-expand-icon"></mat-icon>
    }
  </div>
</ng-template>
