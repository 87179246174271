import { Component } from '@angular/core';
import { PresentationV2 } from '../../../models';

@Component({
  selector: 'lib-skeleton-page',
  templateUrl: './skeleton-page.component.html',
  styleUrl: './skeleton-page.component.scss',
})
export class SkeletonPageComponent {
  public readonly ePresentationV2 = PresentationV2;
}
