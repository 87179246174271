<div class="field-form-container p-2">
  <div
    #field
    [ngClass]="{
      'deactivated-field': valueDefinition | isDeactivated,
      inactive: (valueDefinitionGroup | isDeactivated) || !!valueDefinition.hidden_by_taxonomy,
    }"
    data-testid="fieldContainer"
  >
    @switch (getValueDefinitionFormat(valueDefinition).type) {
      @case (eValueDefinitionType.text_area) {
        <lib-metric-structure-label
          [isTriggered]="isElementTriggered(valueDefinition)"
          [isInactive]="valueDefinition | isDeactivated"
          >{{ valueDefinition.label }}</lib-metric-structure-label
        >
        <lib-text-area-input
          class="deactivated-section"
          [control]="dummyControl"
          [hint]="valueDefinition.hint"
          [placeholder]="valueDefinition.type_details?.placeholder ?? ''"
        ></lib-text-area-input>
      }
      @case (eValueDefinitionType.text_rich) {
        <lib-metric-structure-label
          [isTriggered]="isElementTriggered(valueDefinition)"
          [isInactive]="valueDefinition | isDeactivated"
          >{{ valueDefinition.label }}</lib-metric-structure-label
        >
        <div class="rich-text-formfield deactivated-section">
          <lib-rich-text-input [control]="dummyControl"></lib-rich-text-input>
          <mat-hint>{{ valueDefinition.hint }}</mat-hint>
        </div>
      }
      @case (eValueDefinitionType.text_simple) {
        <lib-metric-structure-label
          [isTriggered]="isElementTriggered(valueDefinition)"
          [isInactive]="valueDefinition | isDeactivated"
          >{{ valueDefinition.label }}</lib-metric-structure-label
        >
        <mat-form-field class="deactivated-section">
          <input
            matInput
            [placeholder]="valueDefinition.type_details?.placeholder ?? ''"
            data-testid="simple-text-input"
          />
          <mat-hint>{{ valueDefinition.hint }}</mat-hint>
        </mat-form-field>
      }
      @case (eValueDefinitionType.number) {
        <lib-metric-structure-label
          [isTrigger]="isValueDefinitionTrigger(valueDefinition)"
          [isTriggered]="isElementTriggered(valueDefinition)"
          [isInactive]="valueDefinition | isDeactivated"
        >
          {{ valueDefinition.label }}</lib-metric-structure-label
        >
        <mat-form-field class="deactivated-section">
          <input matInput />
          <div matSuffix class="color-grey-900">
            {{ getUnitSymbol(valueDefinition.type_details!) }}
          </div>
          <mat-hint>{{ valueDefinition.hint }}</mat-hint>
        </mat-form-field>
      }
      @case (eValueDefinitionType.choice) {
        <ng-container *ngTemplateOutlet="choiceField"></ng-container>
      }
      @case (eValueDefinitionType.choice_searchable) {
        <ng-container *ngTemplateOutlet="choiceField"></ng-container>
      }
      @case (eValueDefinitionType.choice_radio) {
        <ng-container *ngTemplateOutlet="choiceField"></ng-container>
      }
      @case (eValueDefinitionType.choice_multiple) {
        <ng-container *ngTemplateOutlet="choiceField"></ng-container>
      }
      @case (eValueDefinitionType.boolean) {
        <lib-metric-structure-label
          [isTriggered]="isElementTriggered(valueDefinition)"
          [isTrigger]="isValueDefinitionTrigger(valueDefinition)"
          [isInactive]="valueDefinition | isDeactivated"
          >{{ valueDefinition.label }}</lib-metric-structure-label
        >
        <div class="deactivated-section">
          <mat-hint class="mb-2">{{ valueDefinition.hint }} </mat-hint>
          <mat-radio-group color="primary" class="fx-col">
            <mat-radio-button [value]="true" class="mat-body-2">
              {{ valueDefinition.type_details?.label_true }}
            </mat-radio-button>
            <mat-radio-button [value]="false" class="mat-body-2">
              {{ valueDefinition.type_details?.label_false }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      }
      @case (eValueDefinitionType.date) {
        <lib-metric-structure-label
          [isTriggered]="isElementTriggered(valueDefinition)"
          [isInactive]="valueDefinition | isDeactivated"
          >{{ valueDefinition.label }}</lib-metric-structure-label
        >
        <mat-form-field class="deactivated-section">
          <input matInput [placeholder]="valueDefinition.type_details!.format" />
          <div matSuffix class="color-grey-800">
            <mat-icon svgIcon="calendar"></mat-icon>
          </div>
          <mat-hint>{{ valueDefinition.hint }}</mat-hint>
        </mat-form-field>
      }
      @case (eValueDefinitionType.datetime) {
        <lib-metric-structure-label
          [isTriggered]="isElementTriggered(valueDefinition)"
          [isInactive]="valueDefinition | isDeactivated"
          >{{ valueDefinition.label }}</lib-metric-structure-label
        >
        <mat-form-field class="deactivated-section">
          <input matInput [placeholder]="valueDefinition.type_details!.format" />
          <div matSuffix class="color-grey-800">
            <mat-icon svgIcon="calendar"></mat-icon>
          </div>
          <mat-hint>{{ valueDefinition.hint }}</mat-hint>
        </mat-form-field>
      }
      @case (eValueDefinitionType.tip) {
        <div class="tip deactivated-section">
          @if (getTipItem(valueDefinition.type_details?.icon)?.icon) {
            <div class="fx-row align-center">
              <mat-icon
                [svgIcon]="getTipItem(valueDefinition.type_details?.icon)?.icon ?? ''"
                class="tip-icon mr-2"
                [class]="'color-' + getTipItem(valueDefinition.type_details?.icon)?.image"
              >
              </mat-icon>
            </div>
          }
          <div
            class="text mat-body pl-3 tip-text"
            [class]="'tip-text-' + getTipItem(valueDefinition.type_details?.icon)?.image"
            [innerHTML]="valueDefinition.type_details?.value | sanitizeHtml"
          ></div>
        </div>
      }
      @case (eValueDefinitionType.subtitle) {
        @if (valueDefinition | isDeactivated) {
          <span class="mr-1 status-chip">{{ "Inactive" | translate }}</span>
        }
        <h4 class="mb-0 inline-block deactivated-section">{{ valueDefinition.type_details?.value }}</h4>
      }
      @case (eValueDefinitionType.calculated) {
        <lib-metric-structure-label
          [isTriggered]="isElementTriggered(valueDefinition)"
          [isInactive]="valueDefinition | isDeactivated"
          >{{ valueDefinition.label }}</lib-metric-structure-label
        >
        <mat-form-field class="deactivated-section">
          <input matInput [placeholder]="valueDefinition.type_details?.formula ?? ''" />
          <div matSuffix>{{ getUnitSymbol(valueDefinition.type_details!) }}</div>
        </mat-form-field>
      }
      @case (eValueDefinitionType.file_v2) {
        <lib-metric-structure-label
          labelClass="upload"
          [isTriggered]="isElementTriggered(valueDefinition)"
          [isInactive]="valueDefinition | isDeactivated"
        >
          {{ valueDefinition.label }}
        </lib-metric-structure-label>
        <div class="deactivated-section">
          <div class="file-upload">
            <a class="card card-dashed align-center upload p-0 justify-center" tabindex="1">
              <div class="mat-body-2 color-grey-800">
                {{ "Drag files to import, or" | translate }}
                <span>
                  <button lib-button buttonType="browse">{{ "browse" | translate }}<input type="file" /></button
                ></span>
              </div>
            </a>
          </div>
          <div class="mt-1 fx-row justify-space-between">
            <div class="label">{{ valueDefinition.hint }}</div>
            <div class="label">{{ "150MB" | translate }}</div>
          </div>
        </div>
      }
      @case (eValueDefinitionType.document) {
        <lib-metric-structure-document-field [valueDefinition]="valueDefinition"></lib-metric-structure-document-field>
      }
      @default {
        {{ valueDefinition.label }}
      }
    }
  </div>
  <div
    class="overlay"
    [ngClass]="{
      active: isActive,
      disabled: (valueDefinitionGroup | isDeactivated),
    }"
    (click)="setSelectedVd(valueDefinition)"
    [attr.data-testid]="'overlay-' + valueDefinition.id"
  >
    @if (!(valueDefinition | isValueDefinitionDragDisabled: metric : isAdmin)) {
      <div class="handler" [attr.data-testid]="'handler-vd-' + valueDefinition.id">
        <mat-icon svgIcon="more"></mat-icon>
      </div>
    }

    <div class="actions">
      @if (valueDefinition | canDeleteValueDefinition: metric : isAdmin) {
        <button
          class="btn btn-sm"
          (click)="deleteField($event, valueDefinitionGroup.value_definitions ?? [], vdIndex)"
          data-testid="deleteFieldButton"
        >
          {{ "Delete" | translate }}
        </button>
      }

      @if (deactivationEnabled || (isAdmin && valueDefinition.published)) {
        @if (
          (valueDefinition | isDeactivated) ||
          (valueDefinition.hidden_by_taxonomy && !valueDefinition.ignore_taxonomies)
        ) {
          <button class="btn btn-sm" (click)="activateField()">
            {{ "Activate" | translate }}
          </button>
        } @else {
          <button class="btn btn-sm" (click)="deactivateField()">{{ "Deactivate" | translate }}</button>
        }
      }
    </div>
  </div>
</div>

<ng-template #choiceField>
  <lib-metric-structure-choice-field
    [valueDefinitionDisplayType]="getValueDefinitionFormat(valueDefinition).type"
    [valueDefinition]="valueDefinition"
    [typeDetails]="valueDefinition.type_details"
    [isTriggered]="isElementTriggered(valueDefinition)"
    [isTrigger]="isValueDefinitionTrigger(valueDefinition)"
  ></lib-metric-structure-choice-field>
</ng-template>
