<lib-rich-text-input
  [control]="valueFormControl"
  [hint]="hint"
  [label]="label"
  [placeholder]="placeholder"
  [autoSaveInterval]="autoSaveInterval"
  [autofocus]="false"
  [changeOnBlur]="true"
  (blur)="onBlur()"
></lib-rich-text-input>
