@if (isRowReorderable) {
  <td class="reorder-column-size">
    <span class="pi pi-bars" pReorderableRowHandle></span>
  </td>
}

@if (enableCheckboxSelection) {
  <td class="action-column-size">
    <p-tableCheckbox [value]="rowData" data-testid="selectCheckbox" />
  </td>
}

@if (isExpandable) {
  <td class="action-column-size">
    <button
      type="button"
      pButton
      pRipple
      [pRowToggler]="rowData"
      class="p-button-text p-button-rounded p-button-plain"
      [icon]="isExpanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
      data-testid="expandableIcon"
    ></button>
  </td>
}

@for (column of columns; track column) {
  @if (!(rowGroupingProperties ?? [] | includes: column.dataKey) || rowgroup) {
    <td
      [attr.rowspan]="(rowGroupingProperties ?? [] | includes: column.dataKey) && rowgroup ? rowspan : 1"
      [ngStyle]="{
        width: column.width ?? 'unset',
        'max-width': column.width ?? 'unset',
        'min-width': column.minWidth ?? 'unset',
        'background-color': column.backgroundColor?.(rowData) ?? 'unset',
      }"
      [ngClass]="{ 'separator-cell': column.isSeparator, 'wrapping-value': isWrapping }"
    >
      <div class="outer-cell-content">
        <div class="inner-cell-content" [ngClass]="{ 'ellipsis-value': !isWrapping }" libTitleOnEllipsis>
          <ng-template #defaultCell>
            {{ (rowData | get: column.dataKey) ?? defaultEmptyValue }}
          </ng-template>
          <ng-container
            *ngTemplateOutlet="
              column?.cellTemplate || defaultCell;
              context: { $implicit: rowData | get: column.dataKey, row: rowData }
            "
          ></ng-container>
        </div>
      </div>
    </td>
  }
}

@if (actionMenuItems?.length) {
  <td class="action-column-size">
    <lib-table-body-row-action-cell [rowData]="rowData" [actionMenuItems]="actionMenuItems">
    </lib-table-body-row-action-cell>
  </td>
}
